import React, { useEffect } from 'react';
import FixedNavBar from '../cards/FixedNavBarCard';
import FooterCard from '../cards/FooterCard';
import PrivacyNoticeCard from '../cards/PrivacyNoticeCard';

const PrivacyNote = ({ onLoad }) => {
    useEffect(() => {
        onLoad();
    }, []);
    
    return (
        <div className="desktop" data-devicetype="desktop">
            <div className="flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                id="" style={{ width: "100%" }}>
                <div className="flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                    id="" style={{ width: "100%" }}>
                    <div></div>
                    <FixedNavBar isPrivacyPage={true}/>
                    <PrivacyNoticeCard/>
                    <FooterCard />
                </div>
            </div>
        </div>
    )
};

export default PrivacyNote;