import React from 'react';
import BenefitCard from '../cards/BenefitCard';
import { useContentfulContext } from 'src/context/ContentfulContext';

const Benefits = () => {
    const contentfulContextConsumer = useContentfulContext();
    return (
        <div className="desktop" data-devicetype="desktop">
            <div className="flex-container flex-align-items-stretch
                flex-align-content-flex-start flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                <div className="flex-container
                    flex-align-items-stretch flex-align-content-flex-start
                    flex-full-width fuse ember" id="" style={{ width: "100%" }}></div>
                <div className="background-color-squidink padding-top-xxxlarge
                        padding-bottom-xxxlarge flex-container
                        flex-align-items-stretch flex-align-content-flex-start
                        flex-full-width fuse ember" id="benefits" style={{ width: "100%" }}>
                    <div className="has-max-width">
                        <div className="padding-top-xlarge padding-bottom-xlarge
                                flex-container flex-align-items-stretch
                                flex-align-content-flex-start flex-full-width
                                fuse ember" id="" style={{ width: "100%" }}>
                            <h3 className="heading align-center">{contentfulContextConsumer.Benefit.title}</h3>
                        </div>
                        <div className="padding-bottom-xxlarge flex-container
                                flex-align-items-stretch
                                flex-align-content-flex-start flex-full-width
                                fuse ember" id="" style={{ width: "100%" }}>
                            <BenefitCard data = {contentfulContextConsumer.Benefit.first}/>
                            <BenefitCard data = {contentfulContextConsumer.Benefit.second}/>
                            <BenefitCard data = {contentfulContextConsumer.Benefit.third}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};


export default Benefits;