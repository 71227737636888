import React from "react";
import {useContentfulContext} from "src/context/ContentfulContext";
import PmgNavBar from "src/pmg/components/PmgNavBar";
import PmgFooter from "src/pmg/components/PmgFooter";

const PmgGrowCLM = () => {
    const contentfulContext = useContentfulContext();
    return (
        <div className="desktop" data-devicetype="desktop">
            <div
                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                id=""
            >
                <div
                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id=""
                >
                    <div className="spacer" />
                </div>
                <div
                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id=""
                >
                    <div
                        className="width20 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id=""
                    >
                        <div
                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id=""
                        >
                            <div className="spacer" />
                        </div>
                        <div
                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent is-sticky sticks-top"
                            id=""
                        >
                            <form
                                className="form form-container form-type-redirect"
                                action=""
                                autoComplete="true"
                                encType="application/x-www-form-urlencoded"
                                name=""
                                method="post"
                            >
                                <div
                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <a
                                        className="link-container  "
                                        href="/pmg"
                                    >
                                        <div
                                            className="bg-no-repeat border-color-stone     padding-left-base padding-top-xsmall padding-bottom-xsmall border-bottom-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-stone"
                                            id=""
                                            style={{
                                                width: "100%",
                                                backgroundImage:
                                                    'url("https://m.media-amazon.com/images/G/01/PMG/Squid_ink_sRGB._SL1280_FMpng_.png")',
                                                backgroundSize: "cover",
                                                backgroundPosition: "center center"
                                            }}
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Fuse_Logo_4C_White-Blue-500px_width_copy.png"
                                                    alt=""
                                                    title=""
                                                    height={50}
                                                    style={{ height: 50 }}
                                                />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div
                                    className="width100 padding-left-base padding-right-base padding-top-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <div
                                        className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-homepage"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                                    GROW
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="text align-start color-gulfstream font-size-small ember">
                                            RESOURCES
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-partner-portal"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Partner Portal
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-brand-guidelines"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Brand and Legal
                                                    <br />
                                                    Guidelines
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="text align-start color-gulfstream font-size-small ember">
                                            GUIDANCE
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-customer-experience-guidance"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Customer Experience (CX) Guidance
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-campaign-key-visual-and-messaging-"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Campaign Key Visual and Messaging
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-campaign-planning"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Campaign Planning
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-customer-lifecycle-management"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-bold">
                                                    Customer Lifecycle Management
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-retail-plan"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Retail
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-campaign-approval-process"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Campaign Approval Process
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div
                        className="width79 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id=""
                    >
                        <PmgNavBar/>
                        <div
                            className="width100 border-color-snow     border-left-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-snow"
                            id=""
                        >
                            <div
                                className="bg-no-repeat     padding-left-xxxlarge padding-right-xxxlarge padding-top-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                                style={{
                                    width: "100%",
                                    backgroundImage:
                                        'url("https://m.media-amazon.com/images/G/01/PMG/Wavy_Background_06.22.2_copy._SL1280_FMpng_.png")',
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center"
                                }}
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 padding-right-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <h5 className="heading align-start font-size-xlarge color-gulfstream ember font-bold">
                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.growTitle}
                                            </h5>
                                        </div>

                                        <div
                                            className="width100 padding-right-large flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                <br/>
                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.growDescriptionP1}<br /> <br/>
                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.growDescriptionP2}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="spacer" />
                                    </div>
                                    <div
                                        className="width41 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="image align-start">
                                            <img
                                                src={contentfulContext && contentfulContext.PMGSustainedMarketingPlan.landingImage.fields.file.url}
                                                alt=""
                                                title=""
                                                height="auto"
                                                style={{ height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="image align-start">
                                    <img
                                        src="https://m.media-amazon.com/images/G/01/PMG/Wavy_backgroun_06.22_copy.png"
                                        alt=""
                                        title=""
                                        height="auto"
                                        style={{ height: "auto" }}
                                    />
                                </div>
                            </div>
                            <div
                                className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="spacer" />
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                        {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.milestonesHeading}
                                    </h5>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div
                                                    className="width100 padding-top-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div
                                                        className="width29 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <div
                                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                                <strong>1. </strong>{contentfulContext && contentfulContext.PMGSustainedMarketingPlan.offerAwareness.fields.heading}
                                                            </h5>
                                                        </div>
                                                        <div
                                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <div className="spacer" />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <div
                                                            className="width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <h5 className="heading align-start font-size-small color-gulfstream ember font-bold">
                                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.offerAwareness.fields.milestoneSubHeading}
                                                            </h5>
                                                        </div>
                                                        <div
                                                            className="width100 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                            id=""
                                                        >
                                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.offerAwareness.fields.milestoneDescriptionExtra}
                                                                <br />
                                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.offerAwareness.fields.milestoneDescription}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <div className="spacer" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div
                                                        className="width29 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <div
                                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <h3 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                                <strong>2.</strong> {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.signUp.fields.heading}
                                                            </h3>
                                                        </div>
                                                        <div
                                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <div className="spacer" />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <div
                                                            className="width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <h5 className="heading align-start font-size-small color-gulfstream ember font-bold">
                                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.signUp.fields.milestoneSubHeading}
                                                            </h5>
                                                        </div>
                                                        <div
                                                            className="width100 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                            id=""
                                                        >
                                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.signUp.fields.milestoneDescription}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <div className="spacer" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div
                                                        className="width29 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <div
                                                            className="width95 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                                <strong>3. </strong>{contentfulContext && contentfulContext.PMGSustainedMarketingPlan.engagementInTheFirst30DaysOfSignUp.fields.heading}
                                                            </h5>
                                                        </div>
                                                        <div
                                                            className="width58 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <div className="spacer" />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <div
                                                            className="width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <h5 className="heading align-start font-size-small color-gulfstream ember font-bold">
                                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.engagementInTheFirst30DaysOfSignUp.fields.milestoneSubHeading}
                                                            </h5>
                                                        </div>
                                                        <div
                                                            className="width100 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                            id=""
                                                        >
                                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.engagementInTheFirst30DaysOfSignUp.fields.milestoneDescription}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <div className="spacer" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div
                                                    className="width29 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div
                                                        className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                            <strong>4.</strong>{contentfulContext && contentfulContext.PMGSustainedMarketingPlan.first60DaysEngagement.fields.heading}
                                                        </h5>
                                                    </div>
                                                    <div
                                                        className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <div className="spacer" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div
                                                        className="width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <h5 className="heading align-start font-size-small color-gulfstream ember font-bold">
                                                            {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.first60DaysEngagement.fields.milestoneSubHeading}
                                                        </h5>
                                                    </div>
                                                    <div
                                                        className="width100 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                        id=""
                                                    >
                                                        <div className="text align-start color-gulfstream font-size-small ember">
                                                            {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.first60DaysEngagement.fields.milestoneDescription}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <div className="spacer" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width29 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                        <strong>5.</strong> End of subscription/
                                                        <br />
                                                        membership cycle
                                                    </h5>
                                                </div>
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="spacer" />
                                                </div>
                                            </div>
                                            <div
                                                className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div
                                                    className="width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <h5 className="heading align-start font-size-small color-gulfstream ember font-bold">
                                                        {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.endOfSubscriptionMembershipCycle.fields.milestoneSubHeading}
                                                    </h5>
                                                </div>
                                                <div
                                                    className="width100 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                    id=""
                                                >
                                                    <div className="text align-start color-gulfstream font-size-small ember">
                                                        {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.endOfSubscriptionMembershipCycle.fields.milestoneDescription}
                                                    </div>
                                                </div>
                                                <div
                                                    className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="spacer" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width29 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                    <strong>6.</strong> {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.renewalReminder.fields.heading}
                                                </h5>
                                            </div>
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="spacer" />
                                            </div>
                                        </div>
                                        <div
                                            className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className=" width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h5 className="heading align-start font-size-small color-gulfstream ember font-bold">
                                                    {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.renewalReminder.fields.milestoneSubHeading}
                                                </h5>
                                            </div>
                                            <div
                                                className="width100 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.renewalReminder.fields.milestoneDescription}<br />
                                                    <br />
                                                    {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.renewalReminder.fields.milestoneDescriptionExtra}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 padding-right-base flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="has-max-width">
                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.maintainRelevancyTitle}
                                            </h5>
                                        </div>
                                    </div>
                                    <div
                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.maintainRelevancyDescription}
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-large padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                <strong>1.</strong> {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.contentRefreshTitle}
                                            </h5>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.contentRefreshDescription}
                                                </div>
                                            </div>
                                            <div
                                                className="width100 padding-top-xsmall padding-bottom-xsmall flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                                        alt=""
                                                        title=""
                                                        height="auto"
                                                        style={{ height: "auto" }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                    <strong>2.</strong> {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.regularBaseCommsTitle}
                                                </h5>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.regularBaseCommsDescription}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width100 padding-top-xlarge padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="image align-start">
                                            <img
                                                src="https://m.media-amazon.com/images/G/01/PMG/Prime_blue_line.png"
                                                alt=""
                                                title=""
                                                height="auto"
                                                style={{ height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-xmini flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-grow-campaign-planning"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="image align-start">
                                                        <img
                                                            src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Left-SquidInk-Siren.svg"
                                                            alt=""
                                                            title=""
                                                            height="auto"
                                                            style={{ height: "auto" }}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="width45 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-cumulus font-size-small ember">
                                                PREVIOUS
                                            </div>
                                        </div>
                                        <div
                                            className="width45 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-end color-cumulus font-size-small ember">
                                                NEXT
                                            </div>
                                        </div>
                                        <div
                                            className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-grow-retail-plan"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="image align-end">
                                                        <img
                                                            src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Right-Siren.svg"
                                                            alt=""
                                                            title=""
                                                            height="auto"
                                                            style={{ height: "auto" }}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-grow-campaign-planning"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-start color-gulfstream font-size-small ember">
                                                        Campaign Planning
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-grow-retail-plan"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-end color-gulfstream font-size-small ember">
                                                        Retail
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PmgFooter/>
            </div>
        </div>
    )
}

export default PmgGrowCLM;