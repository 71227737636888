import React from 'react';
import PmgFooter from "src/pmg/components/PmgFooter";
import PmgSideBarTimeLine from "src/pmg/components/PmgSideBarTimeLine";
import PmgNavBar from "src/pmg/components/PmgNavBar";
import {useContentfulContext} from "src/context/ContentfulContext";
import constants from "src/constants";

const PmgSustainedMarketingPlan = () => {
    const contentfulContext = useContentfulContext();
    return (
        <div className="desktop" data-devicetype="desktop">
            <div
                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                id=""
            >
                <div
                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id=""
                >
                    <div className="spacer" />
                </div>
                <div
                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id=""
                >
                    <div
                        className="width20 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id=""
                    >
                        <div
                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id=""
                        >
                            <div className="spacer" />
                        </div>
                        <div
                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent is-sticky sticks-top"
                            id=""
                        >
                            <PmgSideBarTimeLine currentPageName={constants.PMG_START_SIDE_TIMELINE_NAMES.CREATE_SUSTAIN_PLAN}/>
                        </div>
                    </div>
                    <div
                        className="width79 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id=""
                    >
                        <PmgNavBar/>
                        <div
                            className="width100 border-color-snow     border-left-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-snow"
                            id=""
                        >
                            <div
                                className="bg-no-repeat     padding-left-xxxlarge padding-right-xxxlarge padding-top-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                                style={{
                                    width: "100%",
                                    backgroundImage:
                                        'url("https://m.media-amazon.com/images/G/01/PMG/Wavy_Background_06.22.2_copy._SL1280_FMpng_.png")',
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center"
                                }}
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 padding-right-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <h5 className="heading align-start font-size-xlarge color-gulfstream ember font-bold">
                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.title1}
                                            </h5>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <h3 className="heading align-start font-size-regular color-gulfstream ember font-bold">
                                                (Customer Lifecycle Management)
                                            </h3>
                                        </div>
                                        <div
                                            className="width100 padding-top-small padding-bottom-mini flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="bg-repeat-y     padding-top-minibase padding-bottom-minibase flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                                style={{
                                                    width: "4.166666666666667%",
                                                    backgroundImage:
                                                        'url("https://m.media-amazon.com/images/G/01/PMG/Border-Prime_blue._SL1280_FMpng_.png")',
                                                    backgroundSize: "initial",
                                                    backgroundPosition: "top left"
                                                }}
                                            >
                                                <div className="spacer" />
                                            </div>
                                            <div
                                                className="width95 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h5 className="heading align-start font-size-regular color-gulfstream ember font-bold">
                                                    {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.title2}
                                                </h5>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-right-large flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.description1}<br />
                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.description2}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="spacer" />
                                    </div>
                                    <div
                                        className="width41 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="image align-start">
                                            <img
                                                src={contentfulContext && contentfulContext.PMGSustainedMarketingPlan.landingImage.fields.file.url}
                                                alt=""
                                                title=""
                                                height="auto"
                                                style={{ height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="image align-start">
                                    <img
                                        src="https://m.media-amazon.com/images/G/01/PMG/Wavy_backgroun_06.22_copy.png"
                                        alt=""
                                        title=""
                                        height="auto"
                                        style={{ height: "auto" }}
                                    />
                                </div>
                            </div>
                            <div
                                className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="spacer" />
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-mini padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="text align-start color-gulfstream font-size-regular ember">
                                        {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.stageHeading}
                                        <strong>awareness</strong>, <strong>sign-up</strong>,
                                        <strong>engagement</strong> and <strong>renewal</strong>.
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 padding-right-base flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="has-max-width">
                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.stageSubHeading}
                                            </h5>
                                        </div>
                                    </div>
                                    <div
                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="text align-start color-gulfstream font-size-small ember">
                                            {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.stageDescription}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                        {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.milestonesHeading}
                                    </h5>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div
                                                    className="width100 padding-top-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div
                                                        className="width29 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <div
                                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                                <strong>1. </strong>{contentfulContext && contentfulContext.PMGSustainedMarketingPlan.offerAwareness.fields.heading}
                                                            </h5>
                                                        </div>
                                                        <div
                                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <div className="spacer" />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <div
                                                            className="width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <h5 className="heading align-start font-size-small color-gulfstream ember font-bold">
                                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.offerAwareness.fields.milestoneSubHeading}
                                                            </h5>
                                                        </div>
                                                        <div
                                                            className="width100 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                            id=""
                                                        >
                                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.offerAwareness.fields.milestoneDescriptionExtra}
                                                                <br />
                                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.offerAwareness.fields.milestoneDescription}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <div className="spacer" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div
                                                        className="width29 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <div
                                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <h3 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                                <strong>2.</strong> {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.signUp.fields.heading}
                                                            </h3>
                                                        </div>
                                                        <div
                                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <div className="spacer" />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <div
                                                            className="width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <h5 className="heading align-start font-size-small color-gulfstream ember font-bold">
                                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.signUp.fields.milestoneSubHeading}
                                                            </h5>
                                                        </div>
                                                        <div
                                                            className="width100 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                            id=""
                                                        >
                                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.signUp.fields.milestoneDescription}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <div className="spacer" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div
                                                        className="width29 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <div
                                                            className="width95 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                                <strong>3. </strong>{contentfulContext && contentfulContext.PMGSustainedMarketingPlan.engagementInTheFirst30DaysOfSignUp.fields.heading}
                                                            </h5>
                                                        </div>
                                                        <div
                                                            className="width58 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <div className="spacer" />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <div
                                                            className="width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <h5 className="heading align-start font-size-small color-gulfstream ember font-bold">
                                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.engagementInTheFirst30DaysOfSignUp.fields.milestoneSubHeading}
                                                            </h5>
                                                        </div>
                                                        <div
                                                            className="width100 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                            id=""
                                                        >
                                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.engagementInTheFirst30DaysOfSignUp.fields.milestoneDescription}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                        >
                                                            <div className="spacer" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div
                                                    className="width29 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div
                                                        className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                            <strong>4.</strong>{contentfulContext && contentfulContext.PMGSustainedMarketingPlan.first60DaysEngagement.fields.heading}
                                                        </h5>
                                                    </div>
                                                    <div
                                                        className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <div className="spacer" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div
                                                        className="width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <h5 className="heading align-start font-size-small color-gulfstream ember font-bold">
                                                            {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.first60DaysEngagement.fields.milestoneSubHeading}
                                                        </h5>
                                                    </div>
                                                    <div
                                                        className="width100 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                        id=""
                                                    >
                                                        <div className="text align-start color-gulfstream font-size-small ember">
                                                            {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.first60DaysEngagement.fields.milestoneDescription}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                    >
                                                        <div className="spacer" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width29 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                        <strong>5.</strong> End of subscription/
                                                        <br />
                                                        membership cycle
                                                    </h5>
                                                </div>
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="spacer" />
                                                </div>
                                            </div>
                                            <div
                                                className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div
                                                    className="width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <h5 className="heading align-start font-size-small color-gulfstream ember font-bold">
                                                        {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.endOfSubscriptionMembershipCycle.fields.milestoneSubHeading}
                                                    </h5>
                                                </div>
                                                <div
                                                    className="width100 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                    id=""
                                                >
                                                    <div className="text align-start color-gulfstream font-size-small ember">
                                                        {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.endOfSubscriptionMembershipCycle.fields.milestoneDescription}
                                                    </div>
                                                </div>
                                                <div
                                                    className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="spacer" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width29 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                    <strong>6.</strong> {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.renewalReminder.fields.heading}
                                                </h5>
                                            </div>
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="spacer" />
                                            </div>
                                        </div>
                                        <div
                                            className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className=" width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h5 className="heading align-start font-size-small color-gulfstream ember font-bold">
                                                    {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.renewalReminder.fields.milestoneSubHeading}
                                                </h5>
                                            </div>
                                            <div
                                                className="width100 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.renewalReminder.fields.milestoneDescription}<br />
                                                    <br />
                                                    {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.renewalReminder.fields.milestoneDescriptionExtra}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 padding-right-base flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="has-max-width">
                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.maintainRelevancyTitle}
                                            </h5>
                                        </div>
                                    </div>
                                    <div
                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.maintainRelevancyDescription}
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-large padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                <strong>1.</strong> {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.contentRefreshTitle}
                                            </h5>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.contentRefreshDescription}
                                                </div>
                                            </div>
                                            <div
                                                className="width100 padding-top-xsmall padding-bottom-xsmall flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                                        alt=""
                                                        title=""
                                                        height="auto"
                                                        style={{ height: "auto" }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                    <strong>2.</strong> {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.regularBaseCommsTitle}
                                                </h5>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.regularBaseCommsDescription}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 flex-container flex-align-items-flex-start flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                            {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.usefulLinkTitle}
                                        </h5>
                                    </div>
                                    <div
                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGSustainedMarketingPlan.usefulLinksDescription}
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="spacer" />
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    Download the template
                                                </div>
                                            </div>
                                            <div
                                                className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="spacer" />
                                            </div>
                                            <div
                                                className="fwidth33 lex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    Submit plan for review
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width33 padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <a
                                                    className="link-container  "
                                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=f197f0b7-d2b1-4d62-b0fb-06ff3a96db1e"
                                                    target="_blank"
                                                >
                                                    <div
                                                        className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage:
                                                                'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center center"
                                                        }}
                                                    >
                                                        <div className="text align-center color-pearl font-size-small ember">
                                                            Download
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div
                                                className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="spacer" />
                                            </div>
                                            <div
                                                className="width33 padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <a
                                                    className="link-container  "
                                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetIngest"
                                                    target="_blank"
                                                >
                                                    <div
                                                        className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage:
                                                                'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center center"
                                                        }}
                                                    >
                                                        <div className="text align-center color-pearl font-size-small ember">
                                                            Submit
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width100 padding-top-xlarge padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="image align-start">
                                            <img
                                                src="https://m.media-amazon.com/images/G/01/PMG/Prime_blue_line.png"
                                                alt=""
                                                title=""
                                                height="auto"
                                                style={{ height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-xmini flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-start-create-retail-plan"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="image align-start">
                                                        <img
                                                            src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Left-SquidInk-Siren.svg"
                                                            alt=""
                                                            title=""
                                                            height="auto"
                                                            style={{ height: "auto" }}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="width45 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-cumulus font-size-small ember">
                                                PREVIOUS
                                            </div>
                                        </div>
                                        <div
                                            className="width45 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-end color-cumulus font-size-small ember">
                                                NEXT
                                            </div>
                                        </div>
                                        <div
                                            className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-start-deliver-assets-for-approval"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="image align-end">
                                                        <img
                                                            src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Right-Siren.svg"
                                                            alt=""
                                                            title=""
                                                            height="auto"
                                                            style={{ height: "auto" }}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-start-create-retail-plan"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-start color-gulfstream font-size-small ember">
                                                        Create Retail Plan
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-start-deliver-assets-for-approval"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-end color-gulfstream font-size-small ember">
                                                        Deliver Assets for Approval
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PmgFooter/>
            </div>
        </div>
    )
}

export default PmgSustainedMarketingPlan;