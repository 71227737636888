import React from 'react';
import { useContentfulContext } from 'src/context/ContentfulContext';
import { useInteractionContext } from '../../context/InteractionContext';

const LandingPageBg = (props) => {
    const contentfulContext = useContentfulContext();
    const interactionContext = useInteractionContext();

    return (
        <div className="has-max-width">
            <div className="flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                id="" style={{ width: "100%" }}>
                <div className="flex-container flex-align-items-stretch flex-align-content-flex-start fuse ember"
                    id="" style={{ width: "41.666666666666664%" }}><a className="link-container "
                        href="/">
                        <div className="flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                            id="" style={{ width: "100%" }}>
                            <div data-testid="fuselogo-test" className="image align-start"><img
                                src={contentfulContext && contentfulContext.NavigationBar.logo.fields.file.url}
                                alt="" title="" height="auto" style={{ height: "auto" }} /></div>
                        </div>
                    </a></div>
                <div className="padding-top-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-justify-content flex-justify-content-flex-end fuse ember"
                    id="" style={{ width: "58.333333333333336%" }}>
                    <div className="padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start fuse ember"
                        id="" style={{ width: "25%" }}>
                        <div className="align-end color-white font-size-regular"><a href="#services"
                            onClick={() => {
                                interactionContext.onUserInteraction();
                            }}
                            className="link" style={{textDecoration:"none"}} data-testid='testServices'>{contentfulContext && contentfulContext.NavigationBar.services}</a></div>
                    </div>
                    <div className="padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start fuse ember"
                        id="" style={{ width: "25%" }}>
                        <div className="align-end color-white font-size-regular  "><a href="#benefits"
                            onClick={() => {
                                interactionContext.onUserInteraction();
                            }}
                            className="link" style={{textDecoration:"none"}}>{contentfulContext && contentfulContext.NavigationBar.benefits}</a></div>
                    </div>
                    <div className="padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start fuse ember"
                        id="" style={{ width: "25%" }}>
                        <div className="align-end color-white font-size-regular  "><a href="#how-it-works"
                            onClick={() => {
                                interactionContext.onUserInteraction();
                            }}
                            className="link" style={{textDecoration:"none"}}>{contentfulContext && contentfulContext.NavigationBar.howItWorks}</a></div>
                    </div>
                </div>
            </div>
            <div className="flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                id="" style={{ width: "100%" }}>
                <div className="padding-right-base padding-top-xxxlarge padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start fuse ember"
                    id="" style={{ width: "58.333333333333336%" }}>
                    <div className="padding-bottom-large flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                        id="" style={{ width: "100%" }}>
                        <h1 className="heading align-start">{contentfulContext && contentfulContext.LandingPage.details.title}</h1>
                    </div>
                    <div className="padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                        id="" style={{ width: "100%" }}>
                        <div data-testid="landingpage-description"
                            className="text align-start color-white font-size-large color-appearance-saturated font-weight-light">
                            {contentfulContext && contentfulContext.LandingPage.details.description}</div>
                    </div>
                    <div className="padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                        id="" style={{ width: "100%" }}>
                        <div
                            className="text align-start color-white font-size-regular color-appearance-saturated font-weight-light">
                            {contentfulContext && contentfulContext.LandingPage.details.note}
                            <a href="https://a2z-partners.enterprise.app.tenovos.io"> here</a></div>
                    </div>
                </div>
                <div className="flex-container flex-align-items-stretch flex-align-content-flex-start fuse ember"
                    id="" style={{ width: "41.666666666666664%" }}>
                    <div className="flex-container
                                            flex-align-items-stretch
                                            flex-align-content-flex-start
                                            flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                        <div className="image
                                                align-start"><img
                                src={props.data && props.data.Slider.fields.file.url}
                                alt="" title="" height="auto" style={{ height: "auto" }} /></div>
                    </div>
                    <div className="flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                        id="" style={{ width: "100%" }}>
                        <div className="image align-end"><img
                            src={props.data && props.data.Logo.fields.file.url}
                            alt="" title="" height="50" style={{ height: "50px" }} /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPageBg;