import React, { useEffect } from 'react';
import { useContentfulContext } from '../../context/ContentfulContext';
import { useInteractionContext } from '../../context/InteractionContext';

const FixedNavBar = ({isPrivacyPage}) => {
    const contentfulContext = useContentfulContext();
    const interactionContext = useInteractionContext();

    const addFixedNavigationBar = () => {
        if (window.scrollY > 50) {
            document.getElementById('navbar').classList.add('sticky');
            // add padding top to show content behind navbar
            let navbarHeight = document.querySelector('#navbar').offsetHeight;
            document.body.style.paddingTop = navbarHeight + 'px';
        } else {
            document.getElementById('navbar').classList.remove('sticky');
            // remove padding top from body
            document.body.style.paddingTop = '0';
        }
    }

    useEffect(() => {
        if(!isPrivacyPage){
            window.addEventListener('scroll', addFixedNavigationBar);

            return () => {
                window.removeEventListener('scroll', addFixedNavigationBar);
            }
        } else {
            document.getElementById('navbar').classList.add('sticky');
            document.body.style.paddingTop = '0';
        }
    }, []);

    return (
        <div id='navbar'>
            <div className="background-color-squidink padding-left-base
                        padding-right-base padding-top-small
                        padding-bottom-small flex-container
                        flex-align-items-stretch flex-align-content-flex-start
                        flex-full-width fuse ember is-sticky sticks-top" id="" style={{ width: "100%" }}>
                <div className="has-max-width">
                    <div className="flex-container flex-align-items-stretch
                                flex-align-content-flex-start fuse ember" id="" style={{ width: "41.666666666666664%" }}><a
                            className="link-container " href="/">
                            <div className="flex-container
                                        flex-align-items-stretch
                                        flex-align-content-flex-start
                                        flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                <div className="image
                                            align-start"><img
                                        src={contentfulContext && contentfulContext.NavigationBar.logo.fields.file.url}
                                        alt="" title="" height="25" style={{ height: "25px" }} /></div>
                            </div>
                        </a></div>
                    <div className="flex-container flex-align-items-stretch
                                flex-align-content-flex-start
                                flex-justify-content
                                flex-justify-content-flex-end fuse ember" id="" style={{ width: "58.333333333333336%" }}>
                        <div className="padding-right-base flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start fuse ember" id="" style={{ width: "41.666666666666664%" }}>
                            <div className="align-end color-white
                                        font-size-regular ">
                                <a
                                    href="#services"
                                    onClick={() => {
                                        interactionContext.onUserInteraction();
                                    }}
                                    className="link" style={{ textDecoration: "none" }}>{contentfulContext && contentfulContext.NavigationBar.services}
                                </a>
                            </div>
                        </div>
                        <div className="padding-right-base flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start fuse ember" id="" style={{ width: "16.666666666666668%" }}>
                            <div className="align-end color-white
                                        font-size-regular ">
                                <a
                                    href="#benefits"
                                    onClick={() => {
                                        interactionContext.onUserInteraction();
                                    }}
                                    className="link" style={{ textDecoration: "none" }}>{contentfulContext && contentfulContext.NavigationBar.benefits}
                                </a>
                            </div>
                        </div>
                        <div className="padding-right-base flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start fuse ember" id="" style={{ width: "16.666666666666668%" }}>
                            <div className="align-end color-white
                                        font-size-regular ">
                                <a
                                    href="#how-it-works"
                                    onClick={() => {
                                        interactionContext.onUserInteraction();
                                    }}
                                    className="link" style={{ textDecoration: "none" }}>{contentfulContext && contentfulContext.NavigationBar.howItWorks}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FixedNavBar;