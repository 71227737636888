import React from "react";

const BenefitCard = (props) => {
    return (
        <div data-testid="benefit-card" className="padding-left-xsmall
            padding-right-xsmall flex-container
            flex-align-items-stretch
            flex-align-content-flex-start fuse ember" id="" style={{ width: "33.333333333333336%" }}>
            <div className="boxed background-color-white">
                <div className="flex-container
                    flex-align-items-stretch
                    flex-align-content-flex-start
                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                    <h5 className="heading align-center">{props && props.data.fields.title}</h5>
                </div>
                <div className="padding-top-large
                    padding-bottom-large flex-container
                    flex-align-items-stretch
                    flex-align-content-flex-start
                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                    <div className="image
                        align-center"><img
                        src={props && props.data.fields.image.fields.file.url}
                        alt="" title="" height="auto" style={{ height: "auto" }} />
                    </div>
                </div>
                <div className="flex-container
                    flex-align-items-stretch
                    flex-align-content-flex-start
                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                    <div className="text
                        align-start color-squidink
                        font-size-regular">{props && props.data.fields.paragraph}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default BenefitCard;
