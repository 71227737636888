import React from "react";
import PmgFooter from "src/pmg/components/PmgFooter";
import PmgNavBar from "src/pmg/components/PmgNavBar";
import {useContentfulContext} from "src/context/ContentfulContext";

const PmgGrowBrandAndLegal = () => {
    const contentfulContext = useContentfulContext();
    return (
        <div className="desktop" data-devicetype="desktop">
            <div
                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                id=""
            >
                <div
                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id=""
                >
                    <div className="spacer" />
                </div>
                <div
                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id=""
                >
                    <div
                        className="width20 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id=""
                    >
                        <div
                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id=""
                        >
                            <div className="spacer" />
                        </div>
                        <div
                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent is-sticky sticks-top"
                            id=""
                        >
                            <form
                                className="form form-container form-type-redirect"
                                action=""
                                autoComplete="true"
                                encType="application/x-www-form-urlencoded"
                                name=""
                                method="post"
                            >
                                <div
                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <a
                                        className="link-container  "
                                        href="/pmg"
                                    >
                                        <div
                                            className="bg-no-repeat border-color-stone     padding-left-base padding-top-xsmall padding-bottom-xsmall border-bottom-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-stone"
                                            id=""
                                            style={{
                                                width: "100%",
                                                backgroundImage:
                                                    'url("https://m.media-amazon.com/images/G/01/PMG/Squid_ink_sRGB._SL1280_FMpng_.png")',
                                                backgroundSize: "cover",
                                                backgroundPosition: "center center"
                                            }}
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Fuse_Logo_4C_White-Blue-500px_width_copy.png"
                                                    alt=""
                                                    title=""
                                                    height={50}
                                                    style={{ height: 50 }}
                                                />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div
                                    className="width100 padding-left-base padding-right-base padding-top-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <div
                                        className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-homepage"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                                    GROW
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="text align-start color-gulfstream font-size-small ember">
                                            RESOURCES
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-partner-portal"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Partner Portal
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-brand-guidelines"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-bold">
                                                    Brand and Legal
                                                    <br />
                                                    Guidelines
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="text align-start color-gulfstream font-size-small ember">
                                            GUIDANCE
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-customer-experience-guidance"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Customer Experience (CX) Guidance
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-campaign-key-visual-and-messaging-"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Campaign Key Visual and Messaging
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-campaign-planning"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Campaign Planning
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-customer-lifecycle-management"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Customer Lifecycle Management
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-retail-plan"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Retail
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-campaign-approval-process"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Campaign Approval Process
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div
                        className="width79 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id=""
                    >
                        <PmgNavBar/>
                        <div
                            className="width100 border-color-snow     border-left-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-snow"
                            id=""
                        >
                            <div
                                className="bg-no-repeat     padding-left-xxxlarge padding-right-xxxlarge padding-top-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                                style={{
                                    width: "100%",
                                    backgroundImage:
                                        'url("https://m.media-amazon.com/images/G/01/PMG/Wavy_Background_06.22.2_copy._SL1280_FMpng_.png")',
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center"
                                }}
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <h5 className="heading align-start font-size-xlarge color-gulfstream ember font-normal">
                                                <strong>
                                                    {contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.tItle}
                                                    <br />
                                                    Guidelines
                                                </strong>
                                            </h5>
                                        </div>
                                        <div
                                            className="width100 padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="spacer" />
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.descriptionP1}<br />
                                                <br />
                                                {contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.descriptionP2}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="spacer" />
                                    </div>
                                    <div
                                        className="width41 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="image align-start">
                                            <img
                                                src={contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.landingImage.fields.file.url}
                                                alt=""
                                                title=""
                                                height="auto"
                                                style={{ height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="image align-start">
                                    <img
                                        src="https://m.media-amazon.com/images/G/01/PMG/Wavy_backgroun_06.22_copy.png"
                                        alt=""
                                        title=""
                                        height="auto"
                                        style={{ height: "auto" }}
                                    />
                                </div>
                            </div>
                            <div
                                className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="spacer" />
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div
                                    className="width33 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                        {contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.primeBlg.fields.description}
                                    </h5>
                                </div>
                                <div
                                    className="width33 padding-left-mini padding-right-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                        {contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.pvBlg.fields.description}
                                    </h5>
                                </div>
                                <div
                                    className="width33 padding-left-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                        {contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.pvmeBlg.fields.description}
                                    </h5>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width33 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=8bae0b74-75bc-4114-8f2e-68206ed75e93"
                                            target="_blank"
                                        >
                                            <div
                                                className="bg-no-repeat border-radius-10px    padding-top-base padding-bottom-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                                style={{
                                                    width: "100%",
                                                    backgroundImage:
                                                        'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center center"
                                                }}
                                            >
                                                <div className="image align-center">
                                                    <img
                                                        src={contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.primeBlg.fields.logo.fields.file.url}
                                                        alt=""
                                                        title=""
                                                        height={50}
                                                        style={{ height: 50 }}
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width33 padding-left-mini padding-right-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=f6b861d8-c4e5-48f3-924b-1e6137a759f7"
                                            target="_blank"
                                        >
                                            <div
                                                className="bg-no-repeat border-radius-10px    padding-top-base padding-bottom-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                                style={{
                                                    width: "100%",
                                                    backgroundImage:
                                                        'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center center"
                                                }}
                                            >
                                                <div className="image align-center">
                                                    <img
                                                        src={contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.pvBlg.fields.logo.fields.file.url}
                                                        alt=""
                                                        title=""
                                                        height={50}
                                                        style={{ height: 50 }}
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width33 padding-left-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=d4f7f592-91f8-469e-8bf3-6b836c1241f6"
                                            target="_blank"
                                        >
                                            <div
                                                className="bg-no-repeat border-radius-10px    padding-left-mini padding-right-mini padding-top-base padding-bottom-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                                style={{
                                                    width: "100%",
                                                    backgroundImage:
                                                        'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center center"
                                                }}
                                            >
                                                <div className="image align-center">
                                                    <img
                                                        src={contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.pvmeBlg.fields.logo.fields.file.url}
                                                        alt=""
                                                        title=""
                                                        height={50}
                                                        style={{ height: 50 }}
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xlarge padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div
                                    className="width33 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                        {contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.gamingBlg.fields.description}
                                    </h5>
                                </div>
                                <div
                                    className="width33 padding-left-mini padding-right-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                        {contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.musicBlg.fields.description}
                                    </h5>
                                </div>
                                <div
                                    className="width33 padding-left-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                        {contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.audibleBlg.fields.description}
                                    </h5>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width33 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=25f79db2-4ee9-4a06-b1fd-3442aa785672"
                                            target="_blank"
                                        >
                                            <div
                                                className="bg-no-repeat border-radius-10px    padding-top-mini flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                                style={{
                                                    width: "100%",
                                                    backgroundImage:
                                                        'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Gaming_background._SL1280_FMpng_.png")',
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center center"
                                                }}
                                            >
                                                <div className="image align-center">
                                                    <img
                                                        src={contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.gamingBlg.fields.logo.fields.file.url}
                                                        alt=""
                                                        title=""
                                                        height={100}
                                                        style={{ height: 100 }}
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width33 padding-left-mini padding-right-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=07268fbf-3406-4f93-a5dd-e680f912324c"
                                            target="_blank"
                                        >
                                            <div
                                                className="bg-no-repeat border-radius-10px    padding-top-mini flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                                style={{
                                                    width: "100%",
                                                    backgroundImage:
                                                        'url("https://m.media-amazon.com/images/G/01/PMG/Amazon-Music_Background2._SL1280_FMjpg_.jpg")',
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center center"
                                                }}
                                            >
                                                <div className="image align-center">
                                                    <img
                                                        src={contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.musicBlg.fields.logo.fields.file.url}
                                                        alt=""
                                                        title=""
                                                        height={100}
                                                        style={{ height: 100 }}
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width33 padding-left-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=ad3282b4-48dc-4ad4-8604-a5f9fe4fafaf"
                                            target="_blank"
                                        >
                                            <div
                                                className="bg-no-repeat border-radius-10px    padding-top-small padding-bottom-large flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                                style={{
                                                    width: "100%",
                                                    backgroundImage:
                                                        'url("https://m.media-amazon.com/images/G/01/PMG/Audible_Background._SL1280_FMpng_.png")',
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center center"
                                                }}
                                            >
                                                <div className="image align-center">
                                                    <img
                                                        src={contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.audibleBlg.fields.logo.fields.file.url}
                                                        alt=""
                                                        title=""
                                                        height={50}
                                                        style={{ height: 50 }}
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xlarge padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div
                                    className="width33 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                        {contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.kindleBlg.fields.description}
                                    </h5>
                                </div>
                                <div
                                    className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <div className="spacer" />
                                </div>
                                <div
                                    className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <div className="spacer" />
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width33 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=1e0ebc8a-4f5f-4f8e-81aa-b2254039a2e0"
                                            target="_blank"
                                        >
                                            <div
                                                className="width100 background-color-diamond border-radius-10px    padding-top-large padding-bottom-large flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-center">
                                                    <img
                                                        src={contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.kindleBlg.fields.logo.fields.file.url}
                                                        alt=""
                                                        title=""
                                                        height={25}
                                                        style={{ height: 25 }}
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="spacer" />
                                    </div>
                                    <div
                                        className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="spacer" />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xlarge padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div
                                    className="width33 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                        {contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.fuseBlg.fields.description}
                                    </h5>
                                </div>
                                <div
                                    className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <div className="spacer" />
                                </div>
                                <div
                                    className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <div className="spacer" />
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width33 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=6e77402a-6415-401d-a60d-d4072053507c"
                                            target="_blank"
                                        >
                                            <div
                                                className="width100 background-color-snow border-radius-9px    padding-top-base padding-bottom-minibase flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div
                                                    className="width100 border-radius-10px    flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="image align-center">
                                                        <img
                                                            src={contentfulContext && contentfulContext.PMGGrowBrandAndGuideliens.fuseBlg.fields.logo.fields.file.url}
                                                            alt=""
                                                            title=""
                                                            height={25}
                                                            style={{ height: 25 }}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="width100 padding-top-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <h3 className="heading align-center font-size-small color-gulfstream ember font-normal">
                                                        Legal Requirements
                                                    </h3>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="spacer" />
                                    </div>
                                    <div
                                        className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="spacer" />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width100 padding-top-xlarge padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="image align-start">
                                            <img
                                                src="https://m.media-amazon.com/images/G/01/PMG/Prime_blue_line.png"
                                                alt=""
                                                title=""
                                                height="auto"
                                                style={{ height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-xmini flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-grow-partner-portal"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="image align-start">
                                                        <img
                                                            src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Left-SquidInk-Siren.svg"
                                                            alt=""
                                                            title=""
                                                            height="auto"
                                                            style={{ height: "auto" }}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="width45 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-cumulus font-size-small ember">
                                                PREVIOUS
                                            </div>
                                        </div>
                                        <div
                                            className="width45 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-end color-cumulus font-size-small ember">
                                                NEXT
                                            </div>
                                        </div>
                                        <div
                                            className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-grow-customer-experience-guidance"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="image align-end">
                                                        <img
                                                            src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Right-Siren.svg"
                                                            alt=""
                                                            title=""
                                                            height="auto"
                                                            style={{ height: "auto" }}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-grow-partner-portal"
                                            >
                                                <div
                                                    className="with100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-start color-gulfstream font-size-small ember">
                                                        Partner Portal
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-grow-customer-experience-guidance"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-end color-gulfstream font-size-small ember">
                                                        Customer Experience (CX) Guidance
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id=""
                >
                    <div className="spacer" />
                </div>
                <PmgFooter/>
            </div>
        </div>
    )
}

export default PmgGrowBrandAndLegal;