import React from "react";
import { useContentfulContext } from "src/context/ContentfulContext";

const PrivacyNoticeCard = () => {
    const contentfulContext = useContentfulContext();
    return (
        <div className="padding-left-base padding-right-base padding-top-xxxlarge padding-bottom-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
            id="" style={{ width: "100%" }}>
            <div className="has-max-width">
                <div className="padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                    id="" style={{ width: "100%" }}>
                    <h2 className="heading align-start">{contentfulContext && contentfulContext.PrivacyNotice.title}</h2>
                </div>
                <div className="flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                    id="" style={{ width: "100%" }}>
                    <div className="text align-start color-squidink font-size-regular">{contentfulContext && contentfulContext.PrivacyNotice.description}</div>
                </div>
                <div className="flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                    id="" style={{ width: "100%" }}>
                    <div className="padding-top-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                        id="" style={{ width: "100%" }}>
                        <h4 className="heading align-start">{contentfulContext && contentfulContext.PrivacyNotice.question}</h4>
                    </div>
                    <div className="flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                        id="" style={{ width: "100%" }}>
                        <div className="text align-start color-squidink font-size-regular">{contentfulContext && contentfulContext.PrivacyNotice.answer}<br />
                        <br />{contentfulContext && contentfulContext.PrivacyNotice.example}<br />As a subsidiary of Amazon.com, Inc., Amazon Services
                            LLC follows the same information practices as Amazon.com, and information we collect
                            is subject to:<br />• the <a
                                href="https://www.amazon.com/gp/help/customer/display.html?nodeId=468496"><strong>Amazon.com
                                    Privacy Notice</strong></a> for customers outside of the European Economic
                            Area; and<br />• the <a
                                href="https://www.amazon.co.uk/gp/help/customer/display.html?nodeId=201909010"><strong>Amazon
                                    EU Privacy Notice</strong></a><strong> </strong> for customers in the
                            European Economic Area.</div>
                    </div>
                    <div className="padding-top-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                        id="" style={{ width: "100%" }}>
                        <h4 className="heading align-start">EU-US Privacy Shield</h4>
                    </div>
                    <div className="flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                        id="" style={{ width: "100%" }}>
                        <div className="text align-start color-squidink font-size-regular">Amazon Services LLC
                            participates in the EU-US Privacy Shield framework. <a
                                href="https://www.amazon.com/gp/help/customer/display.html?nodeId=202135380"><strong>Click
                                    here</strong></a> to learn more.<br />Last Updated: June 21, 2018</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyNoticeCard;