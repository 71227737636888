import * as KatalMetrics from '@amzn/katal-metrics';
import constants from './constants';
import { KatalMonitoringDriver } from '@amzn/katal-monitoring-aws-driver';

const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
    const metricsDriver = new KatalMonitoringDriver({
        url: constants.KATAL_MONITORING_URL,
        logToConsole: true,
    });
    //  Attach to global window object so tests can see it
    (window as any).metricsDriver = metricsDriver;
    return metricsDriver;
};

const makePublisher = (): KatalMetrics.Publisher => {

    const metricsDriver = makeMetricsDriver();
    const initialMetricsContext = new KatalMetrics.Context.Builder()
        .withSite('FuseMarketingApp')
        .withServiceName('FuseMarketingAppWebsite')
        .build();
    return new KatalMetrics.Publisher(
        metricsDriver,
        metricsConsoleErrorHandler,
        initialMetricsContext);
};

const initialMetricsPublisher = makePublisher();

export default initialMetricsPublisher;

const countPublisher = initialMetricsPublisher.newChildActionPublisherForMethod("Counts");
export function emitCount(metricName: string, count: number) {
    countPublisher.publish(new KatalMetrics.Metric.Counter(metricName, count));
}
