import React from 'react'
import constants from '../constants';
import { emitCount } from '../metrics';
import PmgStartHomePage from '../pmg/pages/PmgStartHomePage';
import HomePage from './pages/HomePage';
import PrivacyNote from './pages/PrivacyNote';
import PmgPage from 'src/pmg/pages/Pmg';
import PmgIntroToPartnerPortal from 'src/pmg/pages/PmgIntroToPartnerPortal';
import PmgBrandAndLegalGuidance from 'src/pmg/pages/PmgBrandAndLegalGuidance';
import PmgStartAlignOnSchedule from 'src/pmg/pages/PmgStartAlignOnSchedule';
import PmgEstablishCustomerOfferAndAudience from 'src/pmg/pages/PmgEstablishCustomerOfferAndAudience';
import PmgDesignClearAndSimpleCX from 'src/pmg/pages/PmgDesignClearAndSimpleCX';
import PmgDevelopKeyVisualAndMessaging from 'src/pmg/pages/PmgDevelopKeyVisualAndMessaging';
import PmgCreateGTMPlan from 'src/pmg/pages/PmgCreateGTMPlan';
import PmgCreateRetailPlan from 'src/pmg/pages/PmgCreateRetailPlan';
import PmgSustainedMarketingPlan from 'src/pmg/pages/PmgSustainedMarketingPlan';
import PmgDeliveryAssetForApproval from 'src/pmg/pages/PmgDeliveryAssetForApproval';
import PmgGrowHomepage from 'src/pmg/pages/grow/PmgGrowHomepage';
import PmgGrowPartnerPortal from 'src/pmg/pages/grow/PmgGrowPartnerPortal';
import PmgGrowBrandAndLegal from 'src/pmg/pages/grow/PmgGrowBrandAndLegal';
import PmgGrowCXGuidance from 'src/pmg/pages/grow/PmgGrowCXGuidance';
import PmgGrowKVM from 'src/pmg/pages/grow/PmgGrowKVM';
import PmgGrowCampaignPlanning from 'src/pmg/pages/grow/PmgGrowCampaignPlanning';
import PmgGrowCLM from 'src/pmg/pages/grow/PmgGrowCLM';
import PmgGrowRetail from 'src/pmg/pages/grow/PmgGrowRetail';
import PmgGrowSubmitForApproval from 'src/pmg/pages/grow/PmgGrowSubmitForApproval';

export const ROUTES = [{
    path : '/',
    element : <HomePage onLoad={() => {
        emitCount(constants.METRICS.HOME_PAGE, 1);
    }} />
}, {
    path : '/privacy-notice',
    element : <PrivacyNote onLoad={() => {
        emitCount(constants.METRICS.PRIVACY_NOTE_PAGE, 1);
    }} />
}, {
    path : '/pmg',
    element : <PmgPage/>
}, {
    path : '/pmg/pmg-start-homepage',
    element : <PmgStartHomePage/>
}, {
    path : '/pmg/pmg-start-homepage',
    element : <PmgStartHomePage/>
}, {
    path : '/pmg/pmg-start-resources-intro-to-partner-portal',
    element : <PmgIntroToPartnerPortal/>
}, {
    path : '/pmg/pmg-start-resources-amazon-brand-guidelines',
    element : <PmgBrandAndLegalGuidance/>
}, {
    path : '/pmg/pmg-start-align-on-workback-schedule',
    element : <PmgStartAlignOnSchedule/>
}, {
    path : '/pmg/pmg-start-establish-customer-offer-and-audience',
    element : <PmgEstablishCustomerOfferAndAudience/>
}, {
    path : '/pmg/pmg-start-outline-a-clear-and-simple-cx',
    element : <PmgDesignClearAndSimpleCX/>
}, {
    path : '/pmg/start-develop-your-key-visual-and-messaging',
    element : <PmgDevelopKeyVisualAndMessaging/>
}, {
    path : '/pmg/pmg-start-create-gtm-plan',
    element : <PmgCreateGTMPlan/>
}, {
    path : '/pmg/pmg-start-create-retail-plan',
    element : <PmgCreateRetailPlan/>
}, {
    path : '/pmg/pmg-start-create-sustained-marketing-plan',
    element : <PmgSustainedMarketingPlan/>
}, {
    path : '/pmg/pmg-start-deliver-assets-for-approval',
    element : <PmgDeliveryAssetForApproval/>
}, {
    path : '/pmg/pmg-grow-homepage',
    element : <PmgGrowHomepage/>
}, {
    path : '/pmg/pmg-grow-partner-portal',
    element : <PmgGrowPartnerPortal/>
}, {
    path : '/pmg/pmg-grow-brand-guidelines',
    element : <PmgGrowBrandAndLegal/>
}, {
    path : '/pmg/pmg-grow-customer-experience-guidance',
    element : <PmgGrowCXGuidance/>
}, {
    path : '/pmg/pmg-grow-campaign-key-visual-and-messaging-',
    element : <PmgGrowKVM/>
}, {
    path : '/pmg/pmg-grow-campaign-planning',
    element : <PmgGrowCampaignPlanning/>
}, {
    path : '/pmg/pmg-grow-customer-lifecycle-management',
    element : <PmgGrowCLM/>
}, {
    path : '/pmg/pmg-grow-retail-plan',
    element : <PmgGrowRetail/>
}, {
    path : '/pmg/pmg-grow-campaign-approval-process',
    element : <PmgGrowSubmitForApproval/>
}];