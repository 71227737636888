import React from 'react';
import PmgSideBarTimeLine from '../components/PmgSideBarTimeLine';
import PmgNavBar from '../components/PmgNavBar';
import PmgFooter from '../components/PmgFooter';
import PmgLaunchTemplate from '../components/PmgLaunchTemplate';
import { useContentfulContext } from 'src/context/ContentfulContext';
import constants from "src/constants";

const PmgStartAlignOnSchedule = () => {
    const contentfulContext = useContentfulContext();
    return (
        <div className="desktop" data-devicetype="desktop">
            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                id="">
                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id="">
                    <div className="spacer"></div>
                </div>
                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id="">
                    <PmgSideBarTimeLine currentPageName = {constants.PMG_START_SIDE_TIMELINE_NAMES.ALIGN_WORKBACK_SCHEDULE}/>
                    <div className="width79 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id="">
                        <PmgNavBar />
                        <div className="width100 border-color-snow     border-left-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-snow"
                            id="">
                            <div className="bg-no-repeat     padding-left-xxxlarge padding-right-xxxlarge padding-top-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                                style={{
                                    width: "100%",
                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Wavy_Background_06.22.2_copy._SL1280_FMpng_.png')`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}>
                                <div className="has-max-width">
                                    <div className="width54 padding-right-large flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <h5 className="heading align-start font-size-xlarge color-gulfstream ember font-bold">
                                                {contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.title}</h5>
                                        </div>
                                        <div className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="bg-repeat-y     padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                                style={{
                                                    width: "4.166666666666667%",
                                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Border-Prime_blue._SL1280_FMpng_.png')`,
                                                    backgroundSize: "initial",
                                                    backgroundPosition: "top left"
                                                }}>
                                                <div className="spacer"></div>
                                            </div>
                                            <div className="width95 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <h5
                                                    className="heading align-start font-size-regular color-gulfstream ember font-bold">
                                                    {contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.deadlineTitle}</h5>
                                            </div>
                                        </div>
                                        <div className="width100 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.descriptionP1}
                                            <br /><br />{contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.descriptionP2}</div>
                                        </div>
                                    </div>
                                    <div className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                    <div className="width37 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                    <div className="image align-start">
                                        <img src="https://m.media-amazon.com/images/G/01/PMG/Workback_Schedule-16.06-01.png" alt="" title="" height="auto" style={{height:"auto"}}></img>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="image align-start"><img className="heigthauto"
                                src="https://m.media-amazon.com/images/G/01/PMG/Wavy_backgroun_06.22_copy.png" alt=""
                                title="" height="auto"></img></div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xlarge padding-bottom-large flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="spacer"></div>
                            </div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="width20 padding-right-large flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <h5
                                            className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                            {contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.launchTimeline.fields.t1}</h5>
                                    </div>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                </div>
                                <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                    style={{
                                        width: "8.333333333333334%",
                                        backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                        backgroundSize: "initial",
                                        backgroundPosition: "center"
                                    }}>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="image align-center"><img className="height50"
                                            src="https://m.media-amazon.com/images/G/01/PMG/Countown_2.0.png" alt=""
                                            title="" height="50"></img></div>
                                    </div>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                </div>
                                <PmgLaunchTemplate data={contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.marketingKickOff.fields}/>
                            </div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="width20 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="spacer"></div>
                                </div>
                                <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                    style={{
                                        width: "8.333333333333334%",
                                        backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                        backgroundSize: "initial",
                                        backgroundPosition: "center"
                                    }}>
                                    <div className="spacer"></div>
                                </div>
                                <PmgLaunchTemplate data={contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.cxKickOff.fields}/>
                            </div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="width20 padding-right-large flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <h1
                                            className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                            {contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.launchTimeline.fields.t2}</h1>
                                    </div>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                </div>
                                <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                    style={{
                                        width: "8.333333333333334%",
                                        backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                        backgroundSize: "initial",
                                        backgroundPosition: "center"
                                    }}>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="image align-center"><img className="height50"
                                            src="https://m.media-amazon.com/images/G/01/PMG/Countownd_4_copy.png" alt=""
                                            title="" height="50"></img></div>
                                    </div>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                </div>
                                <PmgLaunchTemplate data={contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.customerOfferAndAudienceSubmit.fields}/>
                            </div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="width20 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="spacer"></div>
                                </div>
                                <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                    style={{
                                        width: "8.333333333333334%",
                                        backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                        backgroundSize: "initial",
                                        backgroundPosition: "center"
                                    }}>
                                    <div className="spacer"></div>
                                </div>
                                <PmgLaunchTemplate data={contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.cxSubmitted.fields}/>
                            </div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="width20 padding-right-large flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <h5
                                            className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                            {contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.launchTimeline.fields.t3}</h5>
                                    </div>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                </div>
                                <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                    style={{
                                        width: "8.333333333333334%",
                                        backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                        backgroundSize: "initial",
                                        backgroundPosition: "center"
                                    }}>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="image align-center"><img className="height50"
                                            src="https://m.media-amazon.com/images/G/01/PMG/Countdown_5_copy.png" alt=""
                                            title="" height="50"></img></div>
                                    </div>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                </div>
                                <PmgLaunchTemplate data={contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.keyArtMessagingSubmitted.fields}/>
                            </div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="width20 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="spacer"></div>
                                </div>
                                <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                    style={{
                                        width: "8.333333333333334%",
                                        backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                        backgroundSize: "initial",
                                        backgroundPosition: "center"
                                    }}>
                                    <div className="spacer"></div>
                                </div>
                                <PmgLaunchTemplate data={contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.gtmAndSustainedMarketingPlan.fields}/>
                            </div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="width20 padding-right-large flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <h5
                                            className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                            {contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.launchTimeline.fields.t4}</h5>
                                    </div>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                </div>
                                <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                    style={{
                                        width: "8.333333333333334%",
                                        backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                        backgroundSize: "initial",
                                        backgroundPosition: "center"
                                    }}>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="image align-center"><img className="height50"
                                            src="https://m.media-amazon.com/images/G/01/PMG/Countdown_6_copy.png" alt=""
                                            title="" height="50"></img></div>
                                    </div>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                </div>
                                <PmgLaunchTemplate data = {contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.finalCustomerExperienceCx.fields}/>
                            </div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="width20 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="spacer"></div>
                                </div>
                                <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                    style={{
                                        width: "8.333333333333334%",
                                        backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                        backgroundSize: "initial",
                                        backgroundPosition: "center"
                                    }}>
                                    <div className="spacer"></div>
                                </div>
                                <PmgLaunchTemplate data={contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.marketingAssetsSubmitted.fields}/>
                            </div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="width20 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="spacer"></div>
                                </div>
                                <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                    style={{
                                        width: "8.333333333333334%",
                                        backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                        backgroundSize: "initial",
                                        backgroundPosition: "center"
                                    }}>
                                    <div className="spacer"></div>
                                </div>
                                <PmgLaunchTemplate data={contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.retailMaterialsSubmitted.fields}/>
                            </div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="width20 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="spacer"></div>
                                </div>
                                <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                    style={{
                                        width: "8.333333333333334%",
                                        backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                        backgroundSize: "initial",
                                        backgroundPosition: "center"
                                    }}>
                                    <div className="spacer"></div>
                                </div>
                                <PmgLaunchTemplate data={contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.pressReleaseSubmitted.fields}/>
                            </div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="width20 padding-right-large flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <h5
                                            className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                            {contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.launchTimeline.fields.t5}</h5>
                                    </div>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                </div>
                                <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                    style={{
                                        width: "8.333333333333334%",
                                        backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                        backgroundSize: "initial",
                                        backgroundPosition: "center"
                                    }}>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="image align-center"><img className="height50"
                                            src="https://m.media-amazon.com/images/G/01/PMG/Countdown_7_copy.png" alt=""
                                            title="" height="50"></img></div>
                                    </div>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                </div>
                                <PmgLaunchTemplate data={contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.retailTrainingComplete.fields}/>
                            </div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="width20 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="spacer"></div>
                                </div>
                                <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                    style={{
                                        width: "8.333333333333334%",
                                        backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                        backgroundSize: "initial",
                                        backgroundPosition: "center"
                                    }}>
                                    <div className="spacer"></div>
                                </div>
                                <PmgLaunchTemplate data={contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.assetsLockedIn.fields}/>
                            </div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="width20 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="spacer"></div>
                                </div>
                                <div className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="image align-center"><img className="heigth50"
                                        src="https://m.media-amazon.com/images/G/01/PMG/COuntownd2_copy.png" alt=""
                                        title="" height="50"></img></div>
                                </div>
                                <div className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="spacer"></div>
                                </div>
                            </div>
                        </div>
                        <div className="width100 padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="spacer"></div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="width20 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="spacer"></div>
                                </div>
                                <div className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-justify-content flex-justify-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="image align-center"><img className="height100"
                                            src="https://m.media-amazon.com/images/G/01/PMG/Business_Growth_copy.png"
                                            alt="" title="" height="100"></img></div>
                                    </div>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                </div>
                                <PmgLaunchTemplate data={contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.launch.fields}/>
                            </div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="image align-start"><img className="heigthauto"
                                    src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png" alt="" title=""
                                    height="auto"></img></div>
                            </div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="width25 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                        {contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.usefulLinkTitle}</h5>
                                </div>
                                <div className="width75 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.usefulLinkDescription}</div>
                                    </div>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="width33 padding-top-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""><a className="link-container  "
                                                href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=61fd5f7f-a506-4cf6-9d76-1c3ff749619d"
                                                target="_blank">
                                                <div className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                    style={{
                                                        width: "100%",
                                                        backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png')`,
                                                        backgroundSize: "cover",
                                                        backgroundPosition: "center"
                                                    }}>
                                                    <div className="text align-center color-pearl font-size-small ember">
                                                        {contentfulContext && contentfulContext.PMGAlignWorkbackSchedule.downloadButton}</div>
                                                </div>
                                            </a></div>
                                        <div className="width66 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="spacer"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="">
                            <div className="has-max-width">
                                <div className="padding-top-xlarge padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="image align-start"><img className="heightauto"
                                        src="https://m.media-amazon.com/images/G/01/PMG/Prime_blue_line.png" alt=""
                                        title="" height="auto"></img></div>
                                </div>
                                <div className="width100 padding-bottom-xmini flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""><a className="link-container  "
                                            href="/pmg/pmg-start-resources-amazon-brand-guidelines">
                                            <div className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <div className="image align-start"><img className="height25"
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Left-SquidInk-Siren.svg"
                                                    alt="" title="" height="25"></img></div>
                                            </div>
                                        </a></div>
                                    <div className="width45 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="text align-start color-cumulus font-size-small ember">PREVIOUS</div>
                                    </div>
                                    <div className="width45 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="text align-end color-cumulus font-size-small ember">NEXT</div>
                                    </div>
                                    <div className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""><a className="link-container  "
                                            href="/pmg/pmg-start-establish-customer-offer-and-audience">
                                            <div className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <div className="image align-end"><img className="height25"
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Right-Siren.svg"
                                                    alt="" title="" height="25"></img></div>
                                            </div>
                                        </a></div>
                                </div>
                                <div className="width100 padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="">
                                    <div className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""><a className="link-container  "
                                            href="/pmg/pmg-start-resources-amazon-brand-guidelines">
                                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <div className="text align-start color-gulfstream font-size-small ember">Brand
                                                    and Legal Guidelines</div>
                                            </div>
                                        </a></div>
                                    <div className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""><a className="link-container  "
                                            href="/pmg/pmg-start-establish-customer-offer-and-audience">
                                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <div className="text align-end color-gulfstream font-size-small ember">Establish
                                                    Customer Offer and Audience</div>
                                            </div>
                                        </a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PmgFooter/>
        </div>
    )
}

export default PmgStartAlignOnSchedule;