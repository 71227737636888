import React from 'react';
import { useContentfulContext } from 'src/context/ContentfulContext';
import PmgFooter from '../components/PmgFooter';
import PmgNavBar from '../components/PmgNavBar';
import PmgSideBarTimeLine from '../components/PmgSideBarTimeLine';
import constants from "src/constants";

const PmgStartHomePage = () => {

    const contentfulContext = useContentfulContext();

    return (
        <div className="desktop" data-devicetype="desktop">
            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                id="" >
                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id="" >
                    <div className="spacer"></div>
                </div>
                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id="" >
                    <PmgSideBarTimeLine currentPageName = {constants.PMG_START_SIDE_TIMELINE_NAMES.START_HOME_PAGE}/>
                    <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id="" style={{ width: "79.16666666666667%" }}>
                        <PmgNavBar/>
                        <div className="width100 border-color-snow     border-left-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-snow"
                            id="" >
                            <div className="bg-no-repeat     padding-left-xxxlarge padding-right-xxxlarge padding-top-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                                style={{
                                    width: "100%",
                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Wavy_Background_06.22.2_copy._SL1280_FMpng_.png')`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                ><div className="has-max-width">
                                    <div className="padding-right-large flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="" style={{ width: "50%" }}>
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="" >
                                            <div className="text align-start color-gulfstream font-size-regular ember">WELCOME
                                            </div>
                                        </div>
                                        <div className="width100 padding-right-xmini padding-top-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="" >
                                            <h5 className="heading align-start font-size-medium color-gulfstream ember font-bold">{contentfulContext && contentfulContext.PMGHomepage.landingPageTitle}</h5>
                                        </div>
                                        <div className="width100 padding-right-large padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="" >
                                            <div className="text align-start color-gulfstream font-size-small ember"></div>
                                        </div>
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="" >
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGHomepage.landingPageDescription}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="" style={{ width: "8.333333333333334%" }}>
                                        <div className="spacer"></div>
                                    </div>
                                    <div className="flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id="" style={{ width: "41.666666666666664%" }}>
                                        <div className="image align-center"><img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Welcome_new_partners_c16.05-01.png"
                                            alt="" title="" height="auto" style={{ height: "auto" }}></img></div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="image align-start"><img
                                    src="https://m.media-amazon.com/images/G/01/PMG/Wavy_backgroun_06.22_copy.png" alt=""
                                    title="" height="auto" style={{ height: "auto" }}></img></div>
                            </div>
                            <div className="width100 padding-top-minibase padding-bottom-minibase flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="spacer"></div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="has-max-width">
                                    <div className="width100 padding-right-small flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <div className="has-max-width">
                                            <h5
                                                className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                GTM Requirements</h5>
                                        </div>
                                    </div>
                                    <div className="width100 padding-top-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <h3 className="heading align-start font-size-small color-gulfstream ember font-normal">{contentfulContext && contentfulContext.PMGHomepage.gtmDescription}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="has-max-width">
                                    <div className="image align-start"><img
                                        src="https://m.media-amazon.com/images/G/01/PMG/Timeline_for_start_homepage_white_bgd_27.06-01.png"
                                        alt="" title="" height="auto" style={{ height: "auto" }}></img></div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="has-max-width">
                                    <div className="image align-start"><img
                                        src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png" alt="" title=""
                                        height="auto" style={{ height: "auto" }}></img></div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="has-max-width">
                                    <div className="padding-right-small flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id="" style={{ width: "29.166666666666668%" }}>
                                        <div className="has-max-width">
                                            <h5
                                                className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGResources.title}</h5>
                                        </div>
                                    </div>
                                    <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="" style={{ width: "70.83333333333333%" }}>
                                        <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGResources.description}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="has-max-width">
                                    <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="" style={{ width: "29.166666666666668%" }}>
                                        <div className="spacer"></div>
                                    </div>
                                    <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="" style={{ width: "70.83333333333333%" }}>
                                        <div className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="" >
                                            <div className="image align-start"><img
                                                src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png" alt=""
                                                title="" height="auto" style={{ height: "auto" }}></img></div>
                                        </div>
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="" >
                                            <div className="padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id="" >
                                                <h3
                                                    className="heading align-start font-size-regular color-gulfstream ember font-bold">
                                                    {contentfulContext && contentfulContext.PMGResources.partnerPortal.fields.title}</h3>
                                            </div>
                                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id="" >
                                                <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGResources.partnerPortal.fields.description}</div>
                                            </div>
                                        </div>
                                        <div className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="" >
                                            <div className="spacer"></div>
                                        </div>
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="" ><a className="link-container  "
                                                href="https://a2z-partners.enterprise.app.tenovos.io/#/home">
                                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="" >
                                                    <div className="image align-start"><img
                                                        src={contentfulContext && contentfulContext.PMGResources.partnerPortal.fields.resourceImage.fields.file.url}
                                                        alt="" title="" height="auto" style={{ height: "auto" }}></img></div>
                                                </div>
                                            </a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="has-max-width">
                                    <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="" style={{ width: "29.166666666666668%" }}>
                                        <div className="spacer"></div>
                                    </div>
                                    <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="" style={{ width: "70.83333333333333%" }}>
                                        <div className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="" >
                                            <div className="image align-start"><img
                                                src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png" alt=""
                                                title="" height="auto" style={{ height: "auto" }}></img></div>
                                        </div>
                                        <div className="width100 flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="" >
                                            <div className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id="" >
                                                <h3
                                                    className="heading align-start font-size-regular color-gulfstream ember font-bold">
                                                    {contentfulContext && contentfulContext.PMGResources.brandAndLegalGuidelines.fields.title}</h3>
                                            </div>
                                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id="" >
                                                <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGResources.brandAndLegalGuidelines.fields.description}</div>
                                            </div>
                                        </div>
                                        <div className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="" >
                                            <div className="spacer"></div>
                                        </div>
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="" ><a className="link-container  "
                                                href="/pmg/pmg-start-resources-amazon-brand-guidelines">
                                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="" >
                                                    <div className="image align-start"><img
                                                        src={contentfulContext && contentfulContext.PMGResources.brandAndLegalGuidelines.fields.resourceImage.fields.file.url}
                                                        alt="" title="" height="auto" style={{ height: "auto" }}></img></div>
                                                </div>
                                            </a></div>
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="" >
                                            <div className="spacer"></div>
                                        </div>
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="" >
                                            <div className="spacer"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-bottom-large flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="has-max-width">
                                    <div className="width100 padding-top-xlarge padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <div className="image align-start"><img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Prime_blue_line.png" alt=""
                                            title="" height="auto" style={{ height: "auto" }}></img></div>
                                    </div>
                                    <div className="width100 padding-top-small padding-bottom-xmini flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <div className="flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                            id="" style={{ width: "95.83333333333333%" }}>
                                            <div className="text align-end color-cumulus font-size-small ember">NEXT</div>
                                        </div>
                                        <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id="" style={{ width: "4.166666666666667%" }}><a className="link-container  "
                                                href="/pmg/pmg-start-resources-intro-to-partner-portal">
                                                <div className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="" >
                                                    <div className="image align-end"><img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Right-Siren.svg"
                                                        alt="" title="" height="auto" style={{ height: "auto" }}></img></div>
                                                </div>
                                            </a></div>
                                    </div>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" ><a className="link-container  "
                                            href="/pmg/pmg-start-resources-intro-to-partner-portal">
                                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id="" >
                                                <div className="text align-end color-gulfstream font-size-small ember">Intro to
                                                    Partner Portal</div>
                                            </div>
                                        </a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PmgFooter/>
            </div>
        </div>
    )
}

export default PmgStartHomePage;