import React from 'react';
import { useContentfulContext } from 'src/context/ContentfulContext';
import HowItWorkCard from '../cards/HowItWorkCard';

const HowItWorks = () => {
    const contentfulContextConsumer = useContentfulContext();
    return (
        <div className="desktop" data-devicetype="desktop">
            <div className="flex-container flex-align-items-stretch
                flex-align-content-flex-start flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                <div className="flex-container
                    flex-align-items-stretch flex-align-content-flex-start
                    flex-full-width fuse ember" id="" style={{ width: "100%" }}></div>
                <div className="padding-left-base padding-right-base
                        padding-top-xxxlarge padding-bottom-xxxlarge
                        flex-container flex-align-items-stretch
                        flex-align-content-flex-start flex-full-width fuse
                        ember" id="how-it-works" style={{ width: "100%" }}>
                    <div className="has-max-width max-width-slim">
                        <div className="padding-top-base padding-bottom-xlarge
                                flex-container flex-align-items-stretch
                                flex-align-content-flex-start flex-full-width
                                fuse ember" id="" style={{ width: "100%" }}>
                            <h2 className="heading align-center">{contentfulContextConsumer.HowItWork.title}</h2>
                        </div>
                        <HowItWorkCard data={contentfulContextConsumer.HowItWork.ideate.fields}/>
                        <HowItWorkCard data={contentfulContextConsumer.HowItWork.integrate.fields}/>
                        <HowItWorkCard data={contentfulContextConsumer.HowItWork.propagate.fields}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks;