/* istanbul ignore file */
import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Custom Functional components
import constants from '../constants';
import { client } from '../service/Client';
import * as enricher from '../utilities/enrichContentfulResponse';
import * as customContext from '../context/ContentfulContext';
import { InteractionContext } from '../context/InteractionContext';
import * as utils from '../utilities/utils';

import { ROUTES } from './AppRoute';
import { emitCount } from '../metrics';

import Loading from './loader/Loading';

const App = () => {
    const [contentfulResponse, setContenfulResponse] = React.useState(null);
    const [userInteracted, setUserInteracted] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);

    const fetchData = useCallback(async () => {
        try {
            const res = await client.getEntries();
            const result = enricher.enrichResponse(res);
            setContenfulResponse(result)
        } catch (error) {
            console.log(error);
        }
    }, []);

    const emitInteractionMetric = useCallback(() => {
        emitCount(constants.METRICS.USER_INTERACTION, 1);
    }, []);

    const emitInitialMetrics = useCallback(async () => {
        emitCount(constants.METRICS.APP_LOADED, 1);

        const countryCode = (await utils.getCountryCode())['cloudfront-viewer-country'];
        emitCount(`${constants.METRICS.COUNTRY_CODE}_${countryCode}`, 1);
    }, [])

    useEffect(() => {
        fetchData();
        emitInitialMetrics();
    }, [fetchData]);

    useEffect(() => {
        if (userInteracted) {
            emitInteractionMetric();
        }
    }, [userInteracted]);

    useEffect(() => {
        function onScroll() {
            if (utils.isScrolledTobottom()) {
                setUserInteracted(true);
            }
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    return (
        <InteractionContext.Provider value={{
            onUserInteraction: () => setUserInteracted(true)
        }}>
            <customContext.contentfulContext.Provider value={contentfulResponse}>
                {
                    utils.isNotNull(contentfulResponse)
                        ?
                        <BrowserRouter>
                            <Routes>
                                {
                                    ROUTES.map((route, idx) => (
                                        <Route
                                            key={idx}
                                            path={route.path}
                                            element = {route.element}
                                        />
                                    ))
                                }
                            </Routes>
                        </BrowserRouter>
                        : <Loading/>
                }
            </customContext.contentfulContext.Provider>
        </InteractionContext.Provider>
    )
};

export default App;