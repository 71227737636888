import React from 'react';
import { useContentfulContext } from 'src/context/ContentfulContext';

const PmgNavBar = () => {

    const contentfulContext = useContentfulContext();

    return (
        <div className="bg-no-repeat border-color-storm     padding-top-xsmall padding-bottom-xsmall border-bottom-1px flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-storm is-sticky sticks-top"
            id=""
            style={{
                width: "100%",
                backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Squid_ink_sample_sRGB-01._SL1280_FMpng_.png')`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}>
            <div className="has-max-width">
                <div className="border-color-stone     padding-left-minibase border-left-1px flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-stone"
                    id="" style={{ width: "37.5%" }}>
                    <h5 className="heading align-start font-size-regular color-cloud ember font-normal">
                        <strong>{contentfulContext && contentfulContext.PMGNavBar4.title}</strong></h5>
                </div>
                <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                    id="" style={{ width: "16.666666666666668%" }}>
                    <div className="image align-start"><img
                        src="https://m.media-amazon.com/images/G/01/PMG/image001-3_copy1.png" alt=""
                        title="" height="50" style={{ height: "50px" }}></img></div>
                </div>
                <div className="padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                    id="" style={{ width: "45.833333333333336%" }}>
                    <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id="" style={{ width: "16.666666666666668%" }}><a className="link-container  "
                            href="/pmg/pmg-start-homepage" style={{ textDecoration : "none" }}>
                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="text align-start color-cloud font-size-small ember">{contentfulContext && contentfulContext.PMGNavBar4.start}</div>
                            </div>
                        </a></div>
                    <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id="" style={{ width: "16.666666666666668%" }}><a className="link-container  "
                            href="/pmg/pmg-grow-homepage" style={{textDecoration : "none"}}>
                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="text align-start color-cloud font-size-small ember">{contentfulContext && contentfulContext.PMGNavBar4.grow}</div>
                            </div>
                        </a></div>
                    <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id="" style={{ width: "29.166666666666668%" }}><a className="link-container  "
                            href="https://a2z-partners.enterprise.app.tenovos.io/#/home" target="_blank" style={{ textDecoration : "none" }}>
                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="text align-start color-cloud font-size-small ember">{contentfulContext && contentfulContext.PMGNavBar4.portal}
                                </div>
                            </div>
                        </a></div>
                    <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id="" style={{ width: "37.5%"}}><a className="link-container"
                            href="/pmg/pmg-start-resources-amazon-brand-guidelines"
                            target="_blank" style={{ textDecoration : "none" }}>
                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="text align-start color-cloud font-size-small ember">{contentfulContext && contentfulContext.PMGNavBar4.pautas}</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PmgNavBar;