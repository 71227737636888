import React from 'react';
import { useContentfulContext } from 'src/context/ContentfulContext';
import PmgFooter from '../components/PmgFooter';
import PmgNavBar from '../components/PmgNavBar';
import PmgSideBarTimeLine from '../components/PmgSideBarTimeLine';
import constants from "src/constants";

const PmgIntroToPartnerPortal = () => {
    const contentfulContext = useContentfulContext();
    return (
        <div className="desktop" data-devicetype="desktop">
            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                id="">
                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id="">
                    <div className="spacer"></div>
                </div>
                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id="">
                    <PmgSideBarTimeLine currentPageName = {constants.PMG_START_SIDE_TIMELINE_NAMES.INTRO_TO_PARTNER_PORTAL}/>
                    <div className="width79 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id="">
                        <PmgNavBar />
                        <div className="width100 border-color-snow     border-left-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-snow"
                            id="">
                            <div className="bg-no-repeat     padding-left-xxxlarge padding-right-xxxlarge padding-top-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                                style={{
                                    width: "100%",
                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Wavy_Background_06.22.2_copy._SL1280_FMpng_.png')`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}>
                                <div className="has-max-width">
                                    <div className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">INTRO TO</div>
                                        </div>
                                        <div className="width100 padding-bottom-xsmall flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <h5 className="heading align-start font-size-xlarge color-gulfstream ember font-bold">
                                            {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.title}</h5>
                                        </div>
                                        <div className="width100 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGIntroToPartnerPortal.descriptionP1}<br/><br/>{contentfulContext && contentfulContext.PMGIntroToPartnerPortal.descriptionP2}<br/><br/>{contentfulContext && contentfulContext.PMGIntroToPartnerPortal.descriptionP3}</div>
                                        </div>
                                    </div>
                                    <div className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                    <div className="width41 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="image align-start"><img className='height400'
                                            src={contentfulContext && contentfulContext.PMGIntroToPartnerPortal.landingPageImage.fields.file.url}
                                            alt="" title="" height="400"></img></div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="image align-start"><img
                                    src="https://m.media-amazon.com/images/G/01/PMG/Wavy_backgroun_06.22_copy.png" alt=""
                                    title="" height="auto" style={{ height: "auto" }}></img></div>
                            </div>
                            <div className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="spacer"></div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="width29 padding-right-small flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="has-max-width">
                                            <div className="width100 padding-right-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <div className="has-max-width">
                                                    <h5
                                                        className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                        Learn the basics</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="width100 padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <h5
                                                    className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                    {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.gettingStartedTitle}</h5>
                                            </div>
                                            <div className="bg-no-repeat border-color-summit     border-left-1px border-top-1px border-bottom-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                                style={{
                                                    width: "100%",
                                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Partner_Portal_Video_background_1._SL1280_FMpng_.png')`,
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center"
                                                }}>
                                                <div className="video align-start">
                                                    <video
                                                        controls
                                                        crossOrigin="anonymous"
                                                        height="auto"
                                                        preload="metadata"
                                                    >
                                                        <source
                                                            src={contentfulContext && contentfulContext.PMGIntroToPartnerPortal.gettingStartedVideo.fields.file.url}
                                                            type="video/mp4"
                                                        />
                                                    </video>
                                                </div>
                                            </div>
                                            <div className="width100 padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    <strong>1.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.landingPageDescription.fields.p1}<br /><br />
                                                    <strong>2. </strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.landingPageDescription.fields.p2}<br /><br />
                                                    <strong>3.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.landingPageDescription.fields.p3}<br /><br />
                                                    <strong>4.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.landingPageDescription.fields.p4}<br /><br />
                                                    <strong>5.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.landingPageDescription.fields.p5}</div>
                                            </div>
                                            <div className="width100 padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <div className="image align-start"><img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                                    alt="" title="" height="auto" style={{ height: "auto" }}></img></div>
                                            </div>
                                            <div className="width100 padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <h5
                                                    className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                    {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.findAssetsTitle}</h5>
                                            </div>
                                        </div>
                                        <div className="bg-no-repeat border-color-summit     border-left-1px border-top-1px border-bottom-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                            id=""
                                            style={{
                                                width: "100%",
                                                backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Searching_Assets_Background._SL1280_FMpng_.png')`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center"
                                            }}>
                                            <div className="video align-start">
                                                <video
                                                    controls
                                                    crossOrigin="anonymous"
                                                    height="auto"
                                                    preload="metadata"
                                                >
                                                    <source
                                                        src={contentfulContext && contentfulContext.PMGIntroToPartnerPortal.howToFindAssetsVideo.fields.file.url}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            </div>
                                        </div>
                                        <div className="width100 padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                <strong>1.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.findAssetsDescriptionP1}<br /><br />
                                                <strong>2.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.findAssetsDescriptionP2}</div>
                                        </div>
                                        <div className="width100 padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="image align-start"><img
                                                src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png" alt=""
                                                title="" height="auto" style={{ height: "auto" }}></img></div>
                                        </div>
                                        <div className="width100 padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <h5
                                                className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.downloadAssetsTitle}</h5>
                                        </div>
                                        <div className="bg-no-repeat border-color-summit     border-left-1px border-top-1px border-bottom-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                            id=""
                                            style={{
                                                width: "100%",
                                                backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Downloading_Assets_background._SL1280_FMpng_.png')`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center"
                                            }}>
                                            <div className="video align-start">
                                                <video
                                                    controls
                                                    crossOrigin="anonymous"
                                                    height="auto"
                                                    preload="metadata"
                                                >
                                                    <source
                                                        src={contentfulContext && contentfulContext.PMGIntroToPartnerPortal.howToDownloadAssetsVideo.fields.file.url}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            </div>
                                        </div>
                                        <div className="width100 padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                <strong>1.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.downloadDescriptionP1}<br /><br />
                                                <strong>2.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.downloadDescriptionP2}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="image align-start"><img
                                        src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png" alt="" title=""
                                        height="auto" style={{ height: "auto" }}></img></div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="width29 padding-right-base flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="has-max-width">
                                            <h5
                                                className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.contactSupport}</h5>
                                        </div>
                                    </div>
                                    <div className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <div className="width100 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGIntroToPartnerPortal.contactSupportDescriptionP1}<br /></div>
                                        </div>
                                        <div className="width100 padding-top-xsmall padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGIntroToPartnerPortal.contactSupportDescriptionP2}</div>
                                        </div>
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="width33 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id="" ><a className="link-container  "
                                                    href="mailto:a2z-partner-portal@amazon.com">
                                                    <div className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png')`,
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center"
                                                        }}>
                                                        <div className="text align-center color-pearl font-size-small ember">Contact
                                                            us</div>
                                                    </div>
                                                </a></div>
                                            <div className="width66 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id="" >
                                                <div className="spacer"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="image align-start"><img
                                        src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png" alt="" title=""
                                        height="auto" style={{ height: "auto" }}></img></div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="width29 padding-right-base flex-container flex-align-items-flex-start flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="has-max-width">
                                            <h5
                                                className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.usefulLink}</h5>
                                        </div>
                                    </div>
                                    <div className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <div className="width100 padding-right-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGIntroToPartnerPortal.usefulLinkDescription}<br /></div>
                                        </div>
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id="" ><a className="link-container  "
                                                    href="https://a2z-partners.enterprise.app.tenovos.io/?ld=ELXXSOA-www.amazonfuse.com#/home"
                                                    target="_blank">
                                                    <div className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png')`,
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center"
                                                        }}>
                                                        <div className="text align-center color-pearl font-size-small ember">Visit
                                                        </div>
                                                    </div>
                                                </a></div>
                                            <div className="width66 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id="" >
                                                <div className="spacer"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="width100 padding-top-xlarge padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="image align-start"><img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Prime_blue_line.png" alt=""
                                            title="" height="auto" style={{ height: "auto" }}></img></div>
                                    </div>
                                    <div className="width100 padding-bottom-xmini flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id="" ><a className="link-container  "
                                                href="/pmg/pmg-start-homepage?ld=ELXXSOA-www.amazonfuse.com">
                                                <div className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="">
                                                    <div className="image align-start"><img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Left-SquidInk-Siren.svg"
                                                        alt="" title="" height="25" style={{ height: "25px" }}></img></div>
                                                </div>
                                            </a></div>
                                        <div className="width45 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="text align-start color-cumulus font-size-small ember">PREVIOUS</div>
                                        </div>
                                        <div className="width45 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="text align-end color-cumulus font-size-small ember">NEXT</div>
                                        </div>
                                        <div className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id="" ><a className="link-container  "
                                                href="/pmg/pmg-start-resources-amazon-brand-guidelines?ld=ELXXSOA-www.amazonfuse.com">
                                                <div className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="">
                                                    <div className="image align-end"><img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Right-Siren.svg"
                                                        alt="" title="" height="25" style={{ height: "25px" }}></img></div>
                                                </div>
                                            </a></div>
                                    </div>
                                    <div className="width100 padding-bottom-large flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""><a className="link-container  "
                                                href="/pmg/pmg-start-homepage?ld=ELXXSOA-www.amazonfuse.com">
                                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="">
                                                    <div className="text align-start color-gulfstream font-size-small ember">Welcome
                                                    </div>
                                                </div>
                                            </a></div>
                                        <div className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""><a className="link-container  "
                                                href="/pmg/pmg-start-resources-amazon-brand-guidelines?ld=ELXXSOA-www.amazonfuse.com">
                                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="">
                                                    <div className="text align-end color-gulfstream font-size-small ember">Brand and
                                                        Legal Guidelines</div>
                                                </div>
                                            </a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PmgFooter />
            </div>
        </div>
    )
}

export default PmgIntroToPartnerPortal;
