import React, { useEffect, useState } from 'react';
import { useContentfulContext } from 'src/context/ContentfulContext';
import LandingPageBg from '../cards/LandingPageCard';

const LandingPage = () => {
    const contentfulUserContext = useContentfulContext();
    const landingPageSlides = [contentfulUserContext.LandingPage.Prime, contentfulUserContext.LandingPage.Music, contentfulUserContext.LandingPage.PrimeVideo];
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(Math.floor(Math.random() * 3));
        }, 2000);

        return () => clearInterval(interval);
    }, [index]);

    return (
        <div className="desktop" data-devicetype="desktop">
            <div className="flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                id="" style={{ width: "100%" }}>
                <div className="flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                    id="" style={{ width: "100%" }}>
                            <div className="slideshow-image visible background-color-black   
                                padding-left-base padding-right-base 
                                padding-top-xlarge padding-bottom-base 
                                flex-container flex-align-items-stretch 
                                flex-align-content-flex-start flex-full-width fuse ember" id="" 
                                style={ index !==2 ? {
                                    width: "100%",
                                    backgroundImage: `url(${landingPageSlides[index].Background.fields.file.url})`,
                                    height: "auto"
                                } :
                                {
                                    width: "100%" 
                                }}>
                                <LandingPageBg data={landingPageSlides[index]} />
                            </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPage;