import React from 'react';
import ReactDOM from 'react-dom';
import { KatSpinner } from '@amzn/katal-react';

import App from './components/App';
import './logger';

import '@amzn/katal-components/styles.css';
import './index.scss';

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<KatSpinner size='large' />}>
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
