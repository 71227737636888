import React from 'react'

const PmgLaunchTemplate = (props) => {
    return (
        <div className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
            id="">
            <div className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                id="">
                <h5 className="heading align-start font-size-regular color-gulfstream ember font-bold">
                    {props.data.title}</h5>
            </div>
            <div className="width100 padding-top-mini padding-bottom-xmini flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                id="">
                <h5 className="heading align-start font-size-small color-gulfstream ember font-bold">
                   {props.data.owner}</h5>
            </div>
            <div className="width100 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                id="">
                <div className="text align-start color-gulfstream font-size-small ember">{props.data.description}</div>
            </div>
            <div className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                id="">
                <div className="spacer"></div>
            </div>
        </div>
    )
}

export default PmgLaunchTemplate;