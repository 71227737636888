import React from 'react';
import PmgNavBar from '../components/PmgNavBar';
import PmgFooter from '../components/PmgFooter';
import PmgSideBarTimeLine from '../components/PmgSideBarTimeLine';
import { useContentfulContext } from 'src/context/ContentfulContext';
import constants from "src/constants";

const PmgBrandAndLegalGuidance = () => {
    const contentfulContext = useContentfulContext();
    return (
        <div className="desktop" data-devicetype="desktop">
            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                id="">
                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id="">
                    <div className="spacer"></div>
                </div>
                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id="">
                    <PmgSideBarTimeLine currentPageName = {constants.PMG_START_SIDE_TIMELINE_NAMES.BRAND_AND_LEGAL_GUIDELINES} />
                    <div className="width79 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id="">
                        <PmgNavBar />
                        <div className="width100 border-color-snow     border-left-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-snow"
                            id="">
                            <div className="bg-no-repeat     padding-left-xxxlarge padding-right-xxxlarge padding-top-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                                style={{
                                    width: "100%",
                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Wavy_Background_06.22.2_copy._SL1280_FMpng_.png')`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}>
                                <div className="has-max-width">
                                    <div className="width50 wiflex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="width100 padding-bottom-xsmall flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <h5 className="heading align-start font-size-xlarge color-gulfstream ember font-bold">
                                                {contentfulContext && contentfulContext.PMGBrandLegalGuidelines.title}</h5>
                                        </div>
                                        <div className="width100 padding-top-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGBrandLegalGuidelines.descriptionP1}<br /><br />
                                                {contentfulContext && contentfulContext.PMGBrandLegalGuidelines.descriptionP2}<br /><br />
                                                {contentfulContext && contentfulContext.PMGBrandLegalGuidelines.descriptionP3}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                    <div className="width41 flex-container flex-align-items-flex-end flex-align-content-flex-end amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="image align-start"><img className="heightauto"
                                            src="https://m.media-amazon.com/images/G/01/PMG/brand_and_legal_23.06-01.png"
                                            alt="" title="" height="auto"></img></div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="image align-start"><img className="heightauto"
                                    src="https://m.media-amazon.com/images/G/01/PMG/Wavy_backgroun_06.22_copy.png" alt=""
                                    title="" height="auto"></img></div>
                            </div>
                            <div className="width100 padding-top-minibase padding-bottom-minibase flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="spacer"></div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="bg-no-repeat     padding-top-small flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                        style={{
                                            width: "25%",
                                            backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png')`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center"
                                        }}>
                                        <div className="image align-center"><img className="height50"
                                            src={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.prime.fields.sideLogo.fields.file.url}
                                            alt="" title="" height="50"></img></div>
                                    </div>
                                    <div className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                    <div className="width66 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="image align-start"><img className="heightauto"
                                                src={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.prime.fields.image.fields.file.url}
                                                alt="" title="" height="auto"></img>
                                            </div>
                                        </div>
                                        <div className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="width66 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <div className="width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="">
                                                    <h5 className="heading align-start h5 color-gulfstream ember font-normal">
                                                        <strong>{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.prime.fields.title}</strong></h5>
                                                </div>
                                                <div className="width100 border-color-summit     padding-top-small padding-bottom-small border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                    id="">
                                                    <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.prime.fields.paragraph1}</div>
                                                </div>
                                            </div>
                                            <div className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""><a className="link-container  "
                                                    href={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.prime.fields.learnMoreLink}
                                                    target="_blank">
                                                    <div className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png')`,
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center"
                                                        }}>
                                                        <div className="text align-center color-pearl font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.learnMore}</div>
                                                    </div>
                                                </a></div>
                                        </div>
                                        <div className="width100 border-color-summit     padding-top-small border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.prime.fields.paragraph2}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="image align-start"><img className="heightauto"
                                        src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png" alt="" title=""
                                        height="auto"></img></div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="bg-no-repeat     padding-top-small flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                        style={{
                                            width: "25%",
                                            backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png')`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center"
                                        }}>
                                        <div className="image align-center"><img className="height50"
                                            src={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.primeVideo.fields.logo.fields.file.url} alt=""
                                            title="" height="50"></img></div>
                                    </div>
                                    <div className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                    <div className="width66 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="image align-start"><img className="heightauto"
                                                src={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.primeVideo.fields.image.fields.file.url}
                                                alt="" title="" height="auto"></img>
                                            </div>
                                        </div>
                                        <div className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="width66 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <div className="width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="">
                                                    <h5 className="heading align-start h5 color-gulfstream ember font-bold">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.primeVideo.fields.title}</h5>
                                                </div>
                                                <div className="width100 border-color-summit     padding-top-small padding-bottom-small border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                    id="">
                                                    <div className="text align-start color-gulfstream font-size-small ember">
                                                        {contentfulContext && contentfulContext.PMGBrandLegalGuidelines.primeVideo.fields.paragraph1}</div>
                                                </div>
                                            </div>
                                            <div className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""><a className="link-container  "
                                                    href={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.primeVideo.fields.learnMoreLink}
                                                    target="_blank">
                                                    <div className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png')`,
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center"
                                                        }}>
                                                        <div className="text align-center color-pearl font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.learnMore}</div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="width100 border-color-summit     padding-top-small padding-bottom-small border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.primeVideo.fields.paragraph2}</div>
                                        </div>
                                        <div className="width100 border-color-summit     padding-top-small border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.primeVideo.fields.paragraph3}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="image align-start"><img className="heightauto"
                                        src="https://s3.amazonaws.com/prod-widgetSource/in-shop/pub/images/amzn_favicon_blk.png"
                                        alt="" title="" height="auto"></img></div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="bg-no-repeat     padding-left-mini padding-right-mini flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                        style={{
                                            width: "25%",
                                            backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png')`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center"
                                        }}>
                                        <div className="image align-center"><img className="heightauto"
                                            src={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.pvme.fields.sideLogo.fields.file.url}
                                            alt="" title="" height="50"></img></div>
                                    </div>
                                    <div className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                    <div className="width66 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="image align-start"><img className="heightauto"
                                                src={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.pvme.fields.image.fields.file.url}
                                                alt="" title="" height="auto"></img>
                                            </div>
                                        </div>
                                        <div className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="width66 padding-right-small flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <h5 className="heading align-start h5 color-gulfstream ember font-bold">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.pvme.fields.title}</h5>
                                            </div>
                                            <div className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""><a className="link-container  "
                                                    href={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.pvme.fields.learnMoreLink}
                                                    target="_blank">
                                                    <div className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png')`,
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center"
                                                        }}>
                                                        <div className="text align-center color-pearl font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.learnMore}</div>
                                                    </div>
                                                </a></div>
                                        </div>
                                        <div className="width100 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.pvme.fields.paragraph1}</div>
                                        </div>
                                        <div className="width100 border-color-summit     padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.pvme.fields.paragraph2}</div>
                                        </div>
                                        <div className="width100 border-color-summit     padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.pvme.fields.paragraph3}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="image align-start"><img className="heightauto"
                                        src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png" alt="" title=""
                                        height="auto"></img></div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="bg-no-repeat     padding-top-small flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                        style={{
                                            width: "25%",
                                            backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Prime_Gaming_background._SL1280_FMpng_.png')`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center"
                                        }}>
                                        <div className="image align-center"><img className='height150'
                                            src={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.primeGaming.fields.sideLogo.fields.file.url} alt=""
                                            title="" height="150"></img></div>
                                    </div>
                                    <div className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                    <div className="width66 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="image align-start"><img className='heightauto'
                                                src={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.primeGaming.fields.image.fields.file.url}
                                                alt="" title="" height="auto"></img></div>
                                        </div>
                                        <div className="width100 padding-top-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="width66 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <div className="width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="">
                                                    <h5 className="heading align-start h5 color-gulfstream ember font-bold">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.primeGaming.fields.title}</h5>
                                                </div>
                                                <div className="width100 border-color-summit     padding-top-small padding-bottom-small border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                    id="">
                                                    <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.primeGaming.fields.description}</div>
                                                </div>
                                            </div>
                                            <div className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""><a className="link-container  "
                                                    href={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.primeGaming.fields.learnMoreLink}
                                                    target="_blank">
                                                    <div className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png')`,
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center"
                                                        }}>
                                                        <div className="text align-center color-pearl font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.learnMore}</div>
                                                    </div>
                                                </a></div>
                                        </div>
                                        <div className="width100 padding-top-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="bg-repeat-y     padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                                style={{
                                                    width: "4.166666666666667%",
                                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Border-Prime_blue._SL1280_FMpng_.png')`,
                                                    backgroundSize: "initial",
                                                    backgroundPosition: "top left"
                                                }}>
                                                <div className="spacer"></div>
                                            </div>
                                            <div className="width95 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <h5
                                                    className="heading align-start font-size-regular color-gulfstream ember font-bold">
                                                    {contentfulContext && contentfulContext.PMGBrandLegalGuidelines.primeGaming.fields.title2}</h5>
                                            </div>
                                        </div>
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="width58 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <div className="width100 border-color-summit     padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                    id="">
                                                    <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.primeGaming.fields.paragraph1}</div>
                                                </div>
                                                <div className="width100 border-color-summit     padding-top-small padding-bottom-small border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                    id="">
                                                    <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.primeGaming.fields.paragraph2}</div>
                                                </div>
                                                <div className="width100 border-color-summit     padding-top-small border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                    id="">
                                                    <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.primeGaming.fields.paragraph3}</div>
                                                </div>
                                            </div>
                                            <div className="width41 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <div className="spacer"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="image align-start"><img className='heightauto'
                                        src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png" alt="" title=""
                                        height="auto"></img></div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="bg-no-repeat     padding-top-small flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                        style={{
                                            width: "25%",
                                            backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Amazon-Music_Background2._SL1280_FMjpg_.jpg')`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center"
                                        }}>
                                        <div className="image align-start"><img className='heightauto'
                                            src={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.amazonMusic.fields.sideLogo.fields.file.url}
                                            alt="" title="" height="auto"></img></div>
                                    </div>
                                    <div className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                    <div className="width66 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="image align-start"><img className='heightauto'
                                                src={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.amazonMusic.fields.image.fields.file.url} alt=""
                                                title="" height="auto"></img></div>
                                        </div>
                                        <div className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="width66 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <div className="width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="">
                                                    <h5 className="heading align-start h5 color-gulfstream ember font-bold">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.amazonMusic.fields.title}</h5>
                                                </div>
                                                <div className="width100 border-color-summit     padding-top-small padding-bottom-small border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                    id="">
                                                    <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.amazonMusic.fields.description}</div>
                                                </div>
                                            </div>
                                            <div className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""><a className="link-container  "
                                                    href={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.amazonMusic.fields.learnMoreLink}
                                                    target="_blank">
                                                    <div className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png')`,
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center"
                                                        }}>
                                                        <div className="text align-center color-pearl font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.learnMore}</div>
                                                    </div>
                                                </a></div>
                                        </div>
                                        <div className="width100 padding-top-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="bg-repeat-y     padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                                style={{
                                                    width: "4.166666666666667%",
                                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Border-Prime_blue._SL1280_FMpng_.png')`,
                                                    backgroundSize: "initial",
                                                    backgroundPosition: "top left"
                                                }}>
                                                <div className="spacer"></div>
                                            </div>
                                            <div className="width95 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <h5
                                                    className="heading align-start font-size-regular color-gulfstream ember font-bold">
                                                    {contentfulContext && contentfulContext.PMGBrandLegalGuidelines.amazonMusic.fields.title2}</h5>
                                            </div>
                                        </div>
                                        <div className="width100 padding-top-small padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <h5 className="heading align-start font-size-small color-gulfstream ember font-bold">
                                                {contentfulContext && contentfulContext.PMGBrandLegalGuidelines.amazonMusic.fields.amTitle}</h5>
                                        </div>
                                        <div className="width100 padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.amazonMusic.fields.paragraph1}</div>
                                        </div>
                                        <div className="width100 padding-top-small padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <h5 className="heading align-start font-size-small color-gulfstream ember font-bold">
                                                {contentfulContext && contentfulContext.PMGBrandLegalGuidelines.amazonMusic.fields.amuTitle}</h5>
                                        </div>
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.amazonMusic.fields.paragraph2}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="image align-start"><img className='heightauto'
                                        src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png" alt="" title=""
                                        height="auto"></img></div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="bg-no-repeat     padding-top-small flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                        style={{
                                            width: "25%",
                                            backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Audible_Background._SL1280_FMpng_.png')`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center"
                                        }}>
                                        <div className="image align-center"><img className='height50'
                                            src={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.audible.fields.sideLogo.fields.file.url}
                                            alt="" title="" height="50"></img></div>
                                    </div>
                                    <div className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                    <div className="width66 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="image align-start"><img className='heightauto'
                                                src={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.audible.fields.image.fields.file.url} alt=""
                                                title="" height="auto"></img></div>
                                        </div>
                                        <div className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="width66 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <div className="width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="">
                                                    <h5 className="heading align-start h5 color-gulfstream ember font-bold">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.audible.title}
                                                    </h5>
                                                </div>
                                                <div className="width100 border-color-summit     padding-top-small padding-bottom-small border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                    id="">
                                                    <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.audible.fields.paragraph1}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""><a className="link-container  "
                                                    href={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.audible.fields.learnMoreLink}
                                                    target="_blank">
                                                    <div className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png')`,
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center"
                                                        }}>
                                                        <div className="text align-center color-pearl font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.learnMore}</div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="width100 border-color-summit     padding-top-small padding-bottom-small border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.audible.fields.paragraph2}</div>
                                        </div>
                                        <div className="width100 border-color-summit     padding-top-small border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.audible.fieldsparagraph3}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="image align-start"><img className='heightauto'
                                        src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png" alt="" title=""
                                        height="auto"></img></div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="width25 background-color-diamond     padding-top-small flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="image align-center"><img className='height25'
                                            src={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.kindleUnlimited.fields.logo.fields.file.url}
                                            alt="" title="" height="25"></img>
                                        </div>
                                    </div>
                                    <div className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="spacer"></div>
                                    </div>
                                    <div className="width66 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="image align-start"><img className='heightauto'
                                                src={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.kindleUnlimited.fields.image.fields.file.url} alt=""
                                                title="" height="auto"></img>
                                            </div>
                                        </div>
                                        <div className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="width66 padding-right-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <div className="width100 padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="">
                                                    <h5 className="heading align-start h5 color-gulfstream ember font-bold">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.kindleUnlimited.fields.title}</h5>
                                                </div>
                                                <div className="width100 border-color-summit     padding-top-small padding-bottom-small border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                    id="">
                                                    <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.kindleUnlimited.fields.paragraph1}</div>
                                                </div>
                                            </div>
                                            <div className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""><a className="link-container  "
                                                    href={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.kindleUnlimited.fields.learnMoreLink}
                                                    target="_blank">
                                                    <div className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png')`,
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center"
                                                        }}>
                                                        <div className="text align-center color-pearl font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.learnMore}</div>
                                                    </div>
                                                </a></div>
                                        </div>
                                        <div className="width100 border-color-summit     padding-top-small border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.kindleUnlimited.fields.paragraph2}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="image align-start"><img className='heightauto'
                                        src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png" alt="" title=""
                                        height="auto"></img></div>
                                </div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="width33 padding-right-small flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="has-max-width">
                                            <h5
                                                className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGBrandLegalGuidelines.legalTitle}</h5>
                                        </div>
                                    </div>
                                    <div className="width66 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.legalDescription}</div>
                                        </div>
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="width33 padding-top-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""><a className="link-container  "
                                                    href={contentfulContext && contentfulContext.PMGBrandLegalGuidelines.legalLearnMoreLink}
                                                    target="_blank">
                                                    <div className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png')`,
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center"
                                                        }}>
                                                        <div className="text align-center color-pearl font-size-small ember">{contentfulContext && contentfulContext.PMGBrandLegalGuidelines.learnMore}</div>
                                                    </div>
                                                </a></div>
                                            <div className="width66 padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id="">
                                                <div className="spacer"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="spacer"></div>
                            </div>
                            <div className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="has-max-width">
                                    <div className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="image align-start"><img className='heightauto'
                                            src="https://m.media-amazon.com/images/G/01/PMG/Prime_blue_line.png" alt=""
                                            title="" height="auto"></img></div>
                                    </div>
                                    <div className="width100 padding-bottom-xmini flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""><a className="link-container  "
                                                href="/pmg/pmg-start-resources-intro-to-partner-portal">
                                                <div className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="">
                                                    <div className="image align-start"><img className='heightauto'
                                                        src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Left-SquidInk-Siren.svg"
                                                        alt="" title="" height="auto"></img></div>
                                                </div>
                                            </a></div>
                                        <div className="width41 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="text align-start color-cumulus font-size-small ember">PREVIOUS</div>
                                        </div>
                                        <div className="width50 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                            id="">
                                            <div className="text align-end color-cumulus font-size-small ember">NEXT</div>
                                        </div>
                                        <div className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""><a className="link-container  "
                                                href="/pmg/pmg-start-align-on-workback-schedule">
                                                <div className="flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="">
                                                    <div className="image align-end"><img className='heightauto'
                                                        src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Right-Siren.svg"
                                                        alt="" title="" height="auto"></img></div>
                                                </div>
                                            </a></div>
                                    </div>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="">
                                        <div className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""><a className="link-container  "
                                                href="/pmg/pmg-start-resources-intro-to-partner-portal">
                                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="">
                                                    <div className="text align-start color-gulfstream font-size-small ember">Intro
                                                        to Partner Portal</div>
                                                </div>
                                            </a></div>
                                        <div className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""><a className="link-container  "
                                                href="/pmg/pmg-start-align-on-workback-schedule">
                                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id="">
                                                    <div className="text align-end color-gulfstream font-size-small ember">Align on
                                                        Workback Schedule</div>
                                                </div>
                                            </a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id="">
                    <div className="spacer"></div>
                </div>
                <PmgFooter />
            </div>
        </div>
    )
}

export default PmgBrandAndLegalGuidance;