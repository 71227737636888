/*
A Function to enrich the result API from Contentful
@Param result - Contentful Raw Response
@Return {object} enrichResult
 */
export const enrichResponse = (result) => {
    let enrichResult = {};
    result.items.forEach(item => {
        switch(item.sys.contentType.sys.id) {
            case 'benefits' :
                enrichResult.Benefit = item.fields;
                break;
            case 'contact' :
                enrichResult.ContactForm = item.fields;
                break;
            case 'footer' :
                enrichResult.Footer = item.fields;
                break;
            case 'navigationBar' :
                enrichResult.NavigationBar = item.fields;
                break;
            case 'servicesLego' : 
                enrichResult.Service = item.fields;
                break;
            case 'howItWorks' :
                enrichResult.HowItWork = item.fields;
                break;
            case 'privacyNotice' :
                enrichResult.PrivacyNotice = item.fields
                break
            case 'landingPage':
                enrichResult.LandingPage = {
                    "details" : {
                        "title" : item.fields.title,
                        "description" : item.fields.description,
                        "note" : item.fields.note,
                        "button" : item.fields.button
                    },
                    "PrimeVideo" : {
                        "Logo" : item.fields.pvLogo,
                        "Slider" : item.fields.pvSlider
                    },
                    "Prime" : {
                        "Logo" : item.fields.primeLogo,
                        "Slider" : item.fields.primeSlider,
                        "Background" : item.fields.primeBackground
                    },
                    "Music" : {
                        "Logo" : item.fields.musicLogo,
                        "Slider" : item.fields.musicSlider,
                        "Background" : item.fields.musicBackground
                    }
                }
                break;
            case 'pmgFooter' :
                enrichResult.PMGFooter = item.fields
                break;
            case 'pmgLandingPage' :
                enrichResult.PMGLandingPage = item.fields
                break;
            case 'pmgNavBar2' :
                enrichResult.PMGNavBar2 = item.fields
                break;
            case 'pmgNavBar4' :
                enrichResult.PMGNavBar4 = item.fields
                break;
            case 'gtmRequirementTitles' :
                enrichResult.GTMRequirementTitles = item.fields
                break;
            case 'pmgStartHomePage' : 
                enrichResult.PMGHomepage = item.fields
                break;
            case 'pmgResourceCatalog' : 
                enrichResult.PMGResources = item.fields
                break;
            case 'pmgAlignWorkbackSchedule' :
                enrichResult.PMGAlignWorkbackSchedule = item.fields
                break;
            case 'pmgBrandAndLegalGuidelines' :
                enrichResult.PMGBrandLegalGuidelines = item.fields
                break;
            case 'pmgIntroToPartnerPortal' : 
                enrichResult.PMGIntroToPartnerPortal = item.fields
                break;
            case 'pmgEstablishCustomerOfferAndAudience' :
                enrichResult.PMGEstablishCustomerOfferAndAudience = item.fields
                break;
            case 'pmgDevelopYourKeyVisualAndMessaging' :
                enrichResult.PMGDevelopYourKeyVisualAndMessaging = item.fields
                break;
            case 'pmgCreateGtmPlan' :
                enrichResult.PMGCreateGtmPlan = item.fields
                break;
            case 'pmgDesignClearAndSimpleCx' :
                enrichResult.PMGDesignClearAndSimpleCx = item.fields
                break;
            case 'pmgCreateRetailPlan' :
                enrichResult.PMGCreateRetailPlan = item.fields
                break;
            case 'pmgDeliverAssetsForApproval' :
                enrichResult.PMGDeliverAssetsForApproval = item.fields
                break;
            case 'pmgSustainedMarketingPlan' :
                enrichResult.PMGSustainedMarketingPlan = item.fields
                break;
            case 'pmgGrowHomepage' :
                enrichResult.PMGGrowHomepage = item.fields
                break;
            case 'pmgGrowBrandAndGuideliens' :
                enrichResult.PMGGrowBrandAndGuideliens = item.fields
                break;
            case 'pmgGrowCxGuidance' :
                enrichResult.PMGGrowCxGuidance = item.fields
                break;
        }
    });
    return enrichResult;
}