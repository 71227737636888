const constants = {

    KATAL_MONITORING_URL: process.env.NODE_ENV === 'production' 
        ? 'https://ofd473qte8.execute-api.us-east-1.amazonaws.com/prod/v1/monitoring'
        : 'https://y0pne95y26.execute-api.us-east-1.amazonaws.com/prod/v1/monitoring',

    METRICS: {
        APP_LOADED: 'AppLoaded',
        COUNTRY_CODE: 'CountryCode',
        HOME_PAGE: 'HomePage',
        USER_INTERACTION: 'UserInteraction',
        PRIVACY_NOTE_PAGE: 'PrivacyNotePage',
        PMG_SITE_REDIRECTION : 'PmgSiteDirectionHit'
    },

    PREVIEW_HOST_NAMES : {
        BETA : 'devopreview.marketingwebsite.mbdfuse.amazon.dev',
        PROD : 'preview.marketingwebsite.mbdfuse.amazon.dev'
    },

    PMG_START_SIDE_TIMELINE_NAMES : {
        ALIGN_WORKBACK_SCHEDULE : "alignOnWorkBackSchedule",
        EST_CUSTOMER_OFFER : "establishCustomerOffer",
        DESIGN_SIMPLE_CX : "designSimpleAndClearCX",
        DEVELOP_KVM : "developKeyVisualMessaging",
        CREATE_GTM_PLAN : "createGTMPlan",
        CREATE_RETAIL_PLAN : "createRetailPlan",
        CREATE_SUSTAIN_PLAN : "createSustainMarketingPlan",
        DELIVER_ASSET_FOR_APPROVAL : "deliverAssetForApproval",
        INTRO_TO_PARTNER_PORTAL : "introToPartnerPortal",
        BRAND_AND_LEGAL_GUIDELINES : "brandAndLegalGuidelines",
        START_HOME_PAGE : "startHomePage"
    }
};

export default constants;
