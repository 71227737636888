import React from 'react';
import { Link } from "react-router-dom";

import { useContentfulContext } from '../../context/ContentfulContext';
import { useInteractionContext } from '../../context/InteractionContext';

const FooterCard = () => {
    const contentfulContext = useContentfulContext();
    const interactionContext = useInteractionContext();

    return (
        <div data-testid='footer-test' className="background-color-black padding-left-base
        padding-right-base padding-top-base padding-bottom-base
        flex-container flex-align-items-stretch
        flex-align-content-flex-start flex-justify-content
        flex-justify-content-center flex-full-width fuse ember" id="" style={{ width: "100%" }}>
            <div className="padding-right-base
            padding-top-xmini padding-bottom-base flex-container
            flex-align-items-stretch
            flex-align-content-flex-start fuse ember" id="" style={{ width: "25%" }}>
                <div className="align-center
                color-white font-size-regular ">
                    <a
                        href="https://www.amazon.com/gp/help/customer/display.html/ref=footer_cou?ie=UTF8&amp;nodeId=508088"
                        onClick={() => {
                            interactionContext.onUserInteraction();
                        }}
                        className="link" style={{textDecoration:"none"}} target="_blank">{contentfulContext && contentfulContext.Footer.conditionsOfUse}
                    </a>
                </div>
            </div>
            <div className="padding-right-minibase padding-top-xmini
            flex-container flex-align-items-stretch
            flex-align-content-flex-start fuse ember" id="" style={{ width: "25%" }}>
                <div data-testid='privacy-note' className="align-center
                color-white font-size-regular">
                    <Link to={'/privacy-notice'} style={{textDecoration:"none"}}>{contentfulContext && contentfulContext.Footer.privacyNote}</Link>
                </div>
            </div>
            <div className="background-color-black padding-bottom-base
            flex-container flex-align-items-stretch
            flex-align-content-flex-start fuse ember" id="" style={{ width: "50%" }}>
                <div className="has-max-width">
                    <div className="text align-center color-white
                    font-size-regular">© {contentfulContext && contentfulContext.Footer.copyRights}</div>
                </div>
            </div>
        </div>
    )
}

export default FooterCard;