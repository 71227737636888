import React from 'react';
import FooterCard from '../cards/FooterCard'

const Footer = () => {
    return (
        <div className="desktop" data-devicetype="desktop">
            <div className="flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                id="" style={{ width: "100%" }}>
                <div className="flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width fuse ember"
                    id="" style={{ width: "100%" }}></div>
                <FooterCard/>
            </div>
        </div>
    )
}


export default Footer;