import React from "react";
import PmgFooter from "src/pmg/components/PmgFooter";
import PmgNavBar from "src/pmg/components/PmgNavBar";
import {useContentfulContext} from "src/context/ContentfulContext";

const PmgGrowPartnerPortal = () => {
    const contentfulContext = useContentfulContext();
    return (
        <div className="desktop" data-devicetype="desktop">
            <div
                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                id=""
            >
                <div
                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id=""
                >
                    <div className="spacer" />
                </div>
                <div
                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id=""
                >
                    <div
                        className="width20 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id=""
                    >
                        <div
                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id=""
                        >
                            <div className="spacer" />
                        </div>
                        <div
                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent is-sticky sticks-top"
                            id=""
                        >
                            <form
                                className="form form-container form-type-redirect"
                                action=""
                                autoComplete="true"
                                encType="application/x-www-form-urlencoded"
                                name=""
                                method="post"
                            >
                                <div
                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <a
                                        className="link-container  "
                                        href="/pmg"
                                    >
                                        <div
                                            className="bg-no-repeat border-color-stone     padding-left-base padding-top-xsmall padding-bottom-xsmall border-bottom-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-stone"
                                            id=""
                                            style={{
                                                width: "100%",
                                                backgroundImage:
                                                    'url("https://m.media-amazon.com/images/G/01/PMG/Squid_ink_sRGB._SL1280_FMpng_.png")',
                                                backgroundSize: "cover",
                                                backgroundPosition: "center center"
                                            }}
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Fuse_Logo_4C_White-Blue-500px_width_copy.png"
                                                    alt=""
                                                    title=""
                                                    height={50}
                                                    style={{ height: 50 }}
                                                />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div
                                    className="width100 padding-left-base padding-right-base padding-top-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <div
                                        className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-homepage"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                                    GROW
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="text align-start color-gulfstream font-size-small ember">
                                            RESOURCES
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-partner-portal"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-bold">
                                                    Partner Portal
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-brand-guidelines"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Brand and Legal
                                                    <br />
                                                    Guidelines
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="text align-start color-gulfstream font-size-small ember">
                                            GUIDANCE
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-customer-experience-guidance"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Customer Experience (CX) Guidance
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-campaign-key-visual-and-messaging-"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Campaign Key Visual and Messaging
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-campaign-planning"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Campaign Planning
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-customer-lifecycle-management"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Customer Lifecycle Management
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-retail-plan"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Retail
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-campaign-approval-process"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Campaign Approval Process
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div
                        className="width79 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id=""
                    >
                        <PmgNavBar/>
                        <div
                            className="width100 border-color-snow     border-left-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-snow"
                            id=""
                        >
                            <div
                                className="bg-no-repeat     padding-left-xxxlarge padding-right-xxxlarge padding-top-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                                style={{
                                    width: "100%",
                                    backgroundImage:
                                        'url("https://m.media-amazon.com/images/G/01/PMG/Wavy_Background_06.22.2_copy._SL1280_FMpng_.png")',
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center"
                                }}
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width50 padding-right-large flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <h5 className="heading align-start font-size-xlarge color-gulfstream ember font-bold">
                                                {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.title}
                                            </h5>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.descriptionP1}
                                                <br />
                                                <br />
                                                {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.descriptionP2}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="spacer" />
                                    </div>
                                    <div
                                        className="width41 flex-container flex-align-items-flex-end flex-align-content-flex-end amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="image align-start">
                                            <img
                                                src={contentfulContext && contentfulContext.PMGIntroToPartnerPortal.landingPageImage.fields.file.url}
                                                alt=""
                                                title=""
                                                height={400}
                                                style={{ height: 400 }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="image align-start">
                                    <img
                                        src="https://m.media-amazon.com/images/G/01/PMG/Wavy_backgroun_06.22_copy.png"
                                        alt=""
                                        title=""
                                        height="auto"
                                        style={{ height: "auto" }}
                                    />
                                </div>
                            </div>
                            <div
                                className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="spacer" />
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 padding-right-small flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="has-max-width">
                                            <div
                                                className="width100 padding-right-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="has-max-width">
                                                    <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                        Re-learn the basics
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 padding-bottom-large flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.reLearnDescription}
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width100 padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                    {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.gettingStartedTitle}
                                                </h5>
                                            </div>
                                            <div
                                                className="bg-no-repeat border-color-summit     border-left-1px border-top-1px border-bottom-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                                style={{
                                                    width: "100%",
                                                    backgroundImage:
                                                        'url("https://m.media-amazon.com/images/G/01/PMG/Partner_Portal_Video_background_1._SL1280_FMpng_.png")',
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center center"
                                                }}
                                            >
                                                <div className="video align-start">
                                                    <video
                                                        controls
                                                        crossOrigin="anonymous"
                                                        height="auto"
                                                        preload="metadata"
                                                    >
                                                        <source
                                                            src={contentfulContext && contentfulContext.PMGIntroToPartnerPortal.gettingStartedVideo.fields.file.url}
                                                            type="video/mp4"
                                                        />
                                                    </video>
                                                </div>
                                            </div>
                                            <div
                                                className="width100 padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                                >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    <strong>1.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.landingPageDescription.fields.p1}<br /><br />
                                                    <strong>2.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.landingPageDescription.fields.p2}<br /><br />
                                                    <strong>3.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.landingPageDescription.fields.p3}<br /><br />
                                                    <strong>4.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.landingPageDescription.fields.p4}<br /><br />
                                                    <strong>5.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.landingPageDescription.fields.p5}
                                                </div>
                                            </div>
                                            <div
                                                className="width100 padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                                        alt=""
                                                        title=""
                                                        height="auto"
                                                        style={{ height: "auto" }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="width100 padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">{contentfulContext && contentfulContext.PMGIntroToPartnerPortal.findAssetsTitle}
                                                </h5>
                                            </div>
                                        </div>
                                        <div
                                            className="bg-no-repeat border-color-summit     border-left-1px border-top-1px border-bottom-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                            id=""
                                            style={{
                                                width: "100%",
                                                backgroundImage:
                                                    'url("https://m.media-amazon.com/images/G/01/PMG/Searching_Assets_Background._SL1280_FMpng_.png")',
                                                backgroundSize: "cover",
                                                backgroundPosition: "center center"
                                            }}
                                        >
                                            <div className="video align-start">
                                                <video
                                                    controls
                                                    crossOrigin="anonymous"
                                                    height="auto"
                                                    preload="metadata"
                                                >
                                                    <source
                                                        src={contentfulContext && contentfulContext.PMGIntroToPartnerPortal.howToFindAssetsVideo.fields.file.url}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                <strong>1.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.findAssetsDescriptionP1}<br /><br />
                                                <strong>2.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.findAssetsDescriptionP2}
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                                    alt=""
                                                    title=""
                                                    height="auto"
                                                    style={{ height: "auto" }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.downloadAssetsTitle}
                                            </h5>
                                        </div>
                                        <div
                                            className="bg-no-repeat border-color-summit     border-left-1px border-top-1px border-bottom-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                            id=""
                                            style={{
                                                width: "100%",
                                                backgroundImage:
                                                    'url("https://m.media-amazon.com/images/G/01/PMG/Downloading_Assets_background._SL1280_FMpng_.png")',
                                                backgroundSize: "cover",
                                                backgroundPosition: "center center"
                                            }}
                                        >
                                            <div className="video align-start">
                                                <video
                                                    controls
                                                    crossOrigin="anonymous"
                                                    height="auto"
                                                    preload="metadata"
                                                >
                                                    <source
                                                        src={contentfulContext && contentfulContext.PMGIntroToPartnerPortal.howToDownloadAssetsVideo.fields.file.url}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                <strong>1.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.downloadDescriptionP1}<br /><br />
                                                <strong>2.</strong> {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.downloadDescriptionP2}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 padding-right-small flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="has-max-width">
                                            <div
                                                className="width100 padding-right-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="has-max-width">
                                                    <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                        {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.learnAdvanceFeatures}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.transformAssetsDescription}
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.transformTitle}
                                            </h5>
                                        </div>
                                        <div
                                            className="bg-no-repeat border-color-summit     border-left-1px border-top-1px border-bottom-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                            id=""
                                            style={{
                                                width: "100%",
                                                backgroundImage:
                                                    'url("https://m.media-amazon.com/images/G/01/PMG/Screenshot_2022-06-10_at_18.36.56._SL1280_FMpng_.png")',
                                                backgroundSize: "cover",
                                                backgroundPosition: "center center"
                                            }}
                                        >
                                            <div className="video align-start">
                                                <video
                                                    controls
                                                    crossOrigin="anonymous"
                                                    height="auto"
                                                    preload="metadata"
                                                >
                                                    <source
                                                        src={contentfulContext && contentfulContext.PMGIntroToPartnerPortal.transformingVideo.fields.file.url}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-base padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                <strong>1. </strong> Access more trainings by visiting the
                                                Portal Learning Center
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 padding-right-base flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="has-max-width">
                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.contactSupport}
                                            </h5>
                                        </div>
                                    </div>
                                    <div
                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.contactSupportDescriptionP1}
                                                <br />
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-xsmall flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.contactSupportDescriptionP2}
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width33 padding-top-base flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <a
                                                    className="link-container  "
                                                    href="mailto:a2z-partner-portal@amazon.com"
                                                    target="_blank"
                                                >
                                                    <div
                                                        className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage:
                                                                'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center center"
                                                        }}
                                                    >
                                                        <div className="text align-center color-pearl font-size-small ember">
                                                            Contact us
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div
                                                className="width66 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="spacer" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xxlarge padding-bottom-xxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 padding-right-base flex-container flex-align-items-flex-start flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="has-max-width">
                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.usefulLink}
                                            </h5>
                                        </div>
                                    </div>
                                    <div
                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="https://a2z-partners.enterprise.app.tenovos.io/#/home"
                                            target="_blank"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div
                                                    className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                    style={{
                                                        width: "33.333333333333336%",
                                                        backgroundImage:
                                                            'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                        backgroundSize: "cover",
                                                        backgroundPosition: "center center"
                                                    }}
                                                >
                                                    <div className="text align-center color-pearl font-size-small ember">
                                                        {contentfulContext && contentfulContext.PMGIntroToPartnerPortal.title}
                                                    </div>
                                                </div>
                                                <div
                                                    className="width66 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="spacer" />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width100 padding-top-xlarge padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="image align-start">
                                            <img
                                                src="https://m.media-amazon.com/images/G/01/PMG/Prime_blue_line.png"
                                                alt=""
                                                title=""
                                                height="auto"
                                                style={{ height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-xmini flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-grow-homepage"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="image align-start">
                                                        <img
                                                            src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Left-SquidInk-Siren.svg"
                                                            alt=""
                                                            title=""
                                                            height="auto"
                                                            style={{ height: "auto" }}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="width45 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-cumulus font-size-small ember">
                                                PREVIOUS
                                            </div>
                                        </div>
                                        <div
                                            className="width45 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-end color-cumulus font-size-small ember">
                                                NEXT
                                            </div>
                                        </div>
                                        <div
                                            className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-grow-brand-guidelines"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="image align-end">
                                                        <img
                                                            src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Right-Siren.svg"
                                                            alt=""
                                                            title=""
                                                            height="auto"
                                                            style={{ height: "auto" }}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-grow-homepage"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-start color-gulfstream font-size-small ember">
                                                        Welcome Back!
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-grow-brand-guidelines"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-end color-gulfstream font-size-small ember">
                                                        Brand and Legal Guidelines
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id=""
                >
                    <div className="spacer" />
                </div>
                <PmgFooter/>
            </div>
        </div>
    )
}

export default PmgGrowPartnerPortal;