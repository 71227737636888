import React from 'react';

const HowItWorkCard = (props) => {
    return (
        <div data-testid="howitworks-card" className="padding-top-xlarge padding-bottom-xlarge
                                flex-container flex-align-items-stretch
                                flex-align-content-flex-start flex-full-width
                                fuse ember" id="" style={{ width: "100%" }}>
            <div className="padding-bottom-minibase
                                    flex-container flex-align-items-stretch
                                    flex-align-content-flex-start
                                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                <div className="image
                                        align-center"><img
                        src={props && props.data.imageUrl.fields.file.url}
                        alt="" title="" height="auto" style={{ height: "auto" }} /></div>
            </div>
            <div className="padding-bottom-small flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start
                                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                <h5 className="heading
                                        align-center">{props && props.data.name}</h5>
            </div>
            <div className="flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start
                                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                <div className="text
                                        align-center color-squidink
                                        font-size-regular">{props && props.data.description}</div>
            </div>
        </div>
    )
}


export default HowItWorkCard;