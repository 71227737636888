export const isNotNull = (value) => {
    return value !== null;
}

export const isNotUndefined = (value) => {
    return value !== undefined;
}

export const isNotEmpty = (value) => {
    return value !== '';
}

export const getCountryCode = () => {
    if (process.env.NODE_ENV === 'development') {
        return Promise.resolve({ 'cloudfront-viewer-country': 'IN' });
    }
    return fetch('/countryCode.json').then(res => res.json());
}

/* istanbul ignore next */
export const isScrolledTobottom = () => {
    return (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
}
