import React from 'react';
import { useContentfulContext } from 'src/context/ContentfulContext';
import constants from "src/constants";

const PmgSideBarTimeLine = (props) => {
    const contentfulContext = useContentfulContext();
    return (
        <div className="width20 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
            id="">
            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                id="" >
                <div className="spacer"></div>
            </div>
            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent is-sticky sticks-top"
                id="" >
                <form className="form form-container form-type-redirect" action="" autoComplete="true"
                    encType="application/x-www-form-urlencoded" name="" method="post">
                    <div className="bg-no-repeat     flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                        id=""
                        style={{
                            width: "100%",
                            backgroundImage: `url('/PMG/Squid_ink_sRGB.png')`,
                            backgroundSize: "cover",
                            backgroundPosition: "center"
                        }}
                    ><a className="link-container  " href="/pmg">
                            <div className="bg-no-repeat border-color-storm     padding-left-base padding-top-xsmall padding-bottom-xsmall border-bottom-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-storm"
                                id=""
                                style={{
                                    width: "100%",
                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Squid_ink_sample_sRGB-01._SL1280_FMpng_.png')`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                            ><div className="heigth50 image align-start"><img
                                src="https://m.media-amazon.com/images/G/01/PMG/Fuse_Logo_4C_White-Blue-500px_width_copy.png"
                                alt="" title="" height="50"></img></div>
                            </div>
                        </a></div>
                    <div className="width100 padding-left-base padding-right-base padding-top-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                        id="" >
                        <div className="width95 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id=""><a className="link-container  "
                                href="/pmg/pmg-start-homepage" style={{ textDecoration : "none" }}>
                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <h3
                                        className={"heading align-start font-size-xsmall color-gulfstream ember " + (props.currentPageName === constants.PMG_START_SIDE_TIMELINE_NAMES.START_HOME_PAGE ? "font-bold" : "font-normal")}>
                                        START</h3>
                                </div>
                            </a></div>
                        <div className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="" >
                            <div className="text align-start color-gulfstream font-size-small ember">RESOURCES</div>
                        </div>
                        <div className="width100 padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="" ><a className="link-container "
                                href="/pmg/pmg-start-resources-intro-to-partner-portal" style={{ textDecoration : "none" }}>
                                <div className="width100 padding-left-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <h5
                                        className={"heading align-start font-size-xsmall color-gulfstream ember " + (props.currentPageName === constants.PMG_START_SIDE_TIMELINE_NAMES.INTRO_TO_PARTNER_PORTAL ? "font-bold" : "font-normal")}>
                                        Intro to Partner Portal</h5>
                                </div>
                            </a></div>
                        <div className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="" ><a className="link-container  "
                                href="/pmg/pmg-start-resources-amazon-brand-guidelines" style={{ textDecoration : "none" }}>
                                <div className="width100 padding-left-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <h5
                                        className={"heading align-start font-size-xsmall color-gulfstream ember " + (props.currentPageName === constants.PMG_START_SIDE_TIMELINE_NAMES.BRAND_AND_LEGAL_GUIDELINES ? "font-bold" : "font-normal")}>
                                        Brand and Legal Guidelines</h5>
                                </div>
                            </a></div>
                        <div className="width100 padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="" >
                            <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGHomepage.gtmTitle}
                            </div>
                        </div>
                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="" >
                            <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                                style={{
                                    width: "16.666666666666668%",
                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                    backgroundSize: "initial",
                                    backgroundPosition: "center"
                                }}>
                                <div className="width100 flex-container flex-align-items-flex-start flex-align-content-flex-start flex-justify-content flex-justify-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="height25 image align-center"><img
                                        src="https://m.media-amazon.com/images/G/01/PMG/Countown_copy.png"
                                        alt="" title="" height="25" ></img></div>
                                </div>
                                <div className="width100 padding-top-mini padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="spacer"></div>
                                </div>
                            </div>
                            <div className="width83 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                id=""><a className="link-container  "
                                    href="/pmg/pmg-start-align-on-workback-schedule" style={{ textDecoration : "none" }}>
                                    <div className="width100 padding-top-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <h5 className={"heading align-start font-size-xsmall color-gulfstream ember " + (props.currentPageName === constants.PMG_START_SIDE_TIMELINE_NAMES.ALIGN_WORKBACK_SCHEDULE ? "font-bold" : "font-normal")}>
                                        {contentfulContext && contentfulContext.GTMRequirementTitles.alignOnWorkbackSchedule}</h5>
                                    </div>
                                </a></div>
                        </div>
                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="" >
                            <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                                style={{
                                    width: "16.666666666666668%",
                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                    backgroundSize: "initial",
                                    backgroundPosition: "center"
                                }}
                            ><div className="width100 flex-container flex-align-items-flex-start flex-align-content-flex-start flex-justify-content flex-justify-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                    <div className="height25 image align-center"><img
                                        src="https://m.media-amazon.com/images/G/01/PMG/COuntownd_3_copy.png"
                                        alt="" title="" height="25" ></img></div>
                                </div>
                                <div className="width100 padding-top-mini padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="spacer"></div>
                                </div>
                            </div>
                            <div className="width83 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                id=""><a className="link-container  "
                                    href="/pmg/pmg-start-establish-customer-offer-and-audience " style={{ textDecoration : "none" }}>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <h5 className={"heading align-start font-size-xsmall color-gulfstream ember " + (props.currentPageName === constants.PMG_START_SIDE_TIMELINE_NAMES.EST_CUSTOMER_OFFER ? "font-bold" : "font-normal")}>
                                        {contentfulContext && contentfulContext.GTMRequirementTitles.establishCustomerOfferAndAudience}</h5>
                                    </div>
                                </a></div>
                        </div>
                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="" >
                            <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                                style={{
                                    width: "16.666666666666668%",
                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                    backgroundSize: "initial",
                                    backgroundPosition: "center"
                                }}
                            ><div className="width100 flex-container flex-align-items-flex-start flex-align-content-flex-start flex-justify-content flex-justify-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                    <div className="height25 image align-center"><img
                                        src="https://m.media-amazon.com/images/G/01/PMG/COuntownd_3_copy.png"
                                        alt="" title="" height="25" ></img></div>
                                </div>
                                <div className="width100 padding-top-mini padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="spacer"></div>
                                </div>
                            </div>
                            <div className="width83 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                id=""><a className="link-container  "
                                    href="/pmg/pmg-start-outline-a-clear-and-simple-cx" style={{ textDecoration : "none" }}>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <h5 className={"heading align-start font-size-xsmall color-gulfstream ember " + (props.currentPageName === constants.PMG_START_SIDE_TIMELINE_NAMES.DESIGN_SIMPLE_CX ? "font-bold" : "font-normal")}>
                                        {contentfulContext && contentfulContext.GTMRequirementTitles.designAClearAndSimpleCustomerExperienceCx}</h5>
                                    </div>
                                </a></div>
                        </div>
                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="" >
                            <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                                style={{
                                    width: "16.666666666666668%",
                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                    backgroundSize: "initial",
                                    backgroundPosition: "center"
                                }}
                            >
                                <div className="width100 flex-container flex-align-items-flex-start flex-align-content-flex-start flex-justify-content flex-justify-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="height25 image align-center"><img
                                        src="https://m.media-amazon.com/images/G/01/PMG/Countownd_4_copy.png"
                                        alt="" title="" height="25" ></img></div>
                                </div>
                                <div className="width100 padding-top-mini padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="spacer"></div>
                                </div>
                            </div>
                            <div className="width83 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                id=""><a className="link-container  "
                                    href="/pmg/start-develop-your-key-visual-and-messaging" style={{ textDecoration : "none" }}>
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <h5 className={"heading align-start font-size-xsmall color-gulfstream ember " + (props.currentPageName === constants.PMG_START_SIDE_TIMELINE_NAMES.DEVELOP_KVM ? "font-bold" : "font-normal")}>
                                        {contentfulContext && contentfulContext.GTMRequirementTitles.developYourKeyVisualAndMessaging}</h5>
                                    </div>
                                </a></div>
                        </div>
                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="" >
                            <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                                style={{
                                    width: "16.666666666666668%",
                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                    backgroundSize: "initial",
                                    backgroundPosition: "center"
                                }}
                            ><div className="width100 flex-container flex-align-items-flex-start flex-align-content-flex-start flex-justify-content flex-justify-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                    <div className="height25 image align-center"><img
                                        src="https://m.media-amazon.com/images/G/01/PMG/Countownd_4_copy.png"
                                        alt="" title="" height="25" ></img></div>
                                </div>
                                <div className="width100 padding-top-mini padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="spacer"></div>
                                </div>
                            </div>
                            <div className="width83 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                id="" ><a className="link-container  "
                                    href="/pmg/pmg-start-create-gtm-plan" style={{ textDecoration : "none" }}>
                                    <div className="width100 padding-top-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <h5 className={"heading align-start font-size-xsmall color-gulfstream ember " + (props.currentPageName === constants.PMG_START_SIDE_TIMELINE_NAMES.CREATE_GTM_PLAN ? "font-bold" : "font-normal")}>
                                        {contentfulContext && contentfulContext.GTMRequirementTitles.createGoToMarketGtmPlan}</h5>
                                    </div>
                                </a></div>
                        </div>
                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="" >
                            <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                                style={{
                                    width: "16.666666666666668%",
                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                    backgroundSize: "initial",
                                    backgroundPosition: "center"
                                }}
                            >
                                <div className="width100 flex-container flex-align-items-flex-start flex-align-content-flex-start flex-justify-content flex-justify-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="height25 image align-center"><img
                                        src="https://m.media-amazon.com/images/G/01/PMG/Countdown_5_copy.png"
                                        alt="" title="" height="25" ></img></div>
                                </div>
                                <div className="width100 padding-top-mini padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="spacer"></div>
                                </div>
                            </div>
                            <div className="width83 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                id=""><a className="link-container  "
                                    href="/pmg/pmg-start-create-retail-plan"
                                    aria-label="create retail plan" style={{ textDecoration : "none" }}>
                                    <div className="width100 padding-top-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <h5 className={"heading align-start font-size-xsmall color-gulfstream ember " + (props.currentPageName === constants.PMG_START_SIDE_TIMELINE_NAMES.CREATE_RETAIL_PLAN ? "font-bold" : "font-normal")}>
                                            {contentfulContext && contentfulContext.GTMRequirementTitles.createRetailPlan}</h5>
                                    </div>
                                </a></div>
                        </div>
                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="" >
                            <div className="bg-repeat-y     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                id="" style={{
                                    width: "16.666666666666668%",
                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Timeline_line_2px_width._SL1280_FMpng_.png')`,
                                    backgroundSize: "initial",
                                    backgroundPosition: "center"
                                }}
                            ><div className="width100 flex-container flex-align-items-flex-start flex-align-content-flex-start flex-justify-content flex-justify-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                    <div className="height25 image align-center"><img
                                        src="https://m.media-amazon.com/images/G/01/PMG/Countdown_6_copy.png"
                                        alt="" title="" height="25" ></img></div>
                                </div>
                                <div className="width100 padding-top-mini padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="spacer"></div>
                                </div>
                            </div>
                            <div className="width83 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                id=""><a className="link-container  "
                                    href="/pmg/pmg-start-create-sustained-marketing-plan" style={{ textDecoration : "none" }}>
                                    <div className="width100 padding-top-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <h5 className={"heading align-start font-size-xsmall color-gulfstream ember " + (props.currentPageName === constants.PMG_START_SIDE_TIMELINE_NAMES.CREATE_SUSTAIN_PLAN ? "font-bold" : "font-normal")}>
                                        {contentfulContext && contentfulContext.GTMRequirementTitles.createSustainedMarketingPlan}</h5>
                                    </div>
                                </a></div>
                        </div>
                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id="" >
                            <div className="width16 flex-container flex-align-items-flex-start flex-align-content-flex-start flex-justify-content flex-justify-content-center amsg-2018 fonts-loaded border-color-transparent"
                                id="">
                                <div className="height25 image align-center"><img
                                    src="https://m.media-amazon.com/images/G/01/PMG/Countdown_7_copy.png" alt=""
                                    title="" height="25" ></img></div>
                            </div>
                            <div className="width83 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                id=""><a className="link-container  "
                                    href="/pmg/pmg-start-deliver-assets-for-approval" style={{ textDecoration : "none" }}>
                                    <div className="width100 padding-top-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <h5 className={"heading align-start font-size-xsmall color-gulfstream ember " + (props.currentPageName === constants.PMG_START_SIDE_TIMELINE_NAMES.DELIVER_ASSET_FOR_APPROVAL ? "font-bold" : "font-normal")}>
                                        {contentfulContext && contentfulContext.GTMRequirementTitles.deliverAssetsForApproval}</h5>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PmgSideBarTimeLine;