import React from 'react';
import FixedNavBar from '../cards/FixedNavBarCard'

const NavigationBar = () => {
    return (
        <div className="desktop" data-devicetype="desktop">
            <div className="flex-container flex-align-items-stretch
                flex-align-content-flex-start flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                <div className="flex-container
                    flex-align-items-stretch flex-align-content-flex-start
                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                    <FixedNavBar/>
                </div>
            </div>
        </div>
    )
}

export default NavigationBar;