import React from "react";
import {useContentfulContext} from "src/context/ContentfulContext";
import PmgSideBarTimeLine from "src/pmg/components/PmgSideBarTimeLine";
import PmgNavBar from "src/pmg/components/PmgNavBar";
import PmgFooter from "src/pmg/components/PmgFooter";

const PmgGrowSubmitForApproval = () => {
    const contentfulContext = useContentfulContext();
    return(
        <div className="desktop" data-devicetype="desktop">
            <div
                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                id=""
            >
                <div
                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id=""
                >
                    <div className="spacer" />
                </div>
                <div
                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id=""
                >
                    <div
                        className="width20 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id=""
                    >
                        <div
                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id=""
                        >
                            <div className="spacer" />
                        </div>
                        <div
                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent is-sticky sticks-top"
                            id=""
                        >
                            <form
                                className="form form-container form-type-redirect"
                                action=""
                                autoComplete="true"
                                encType="application/x-www-form-urlencoded"
                                name=""
                                method="post"
                            >
                                <div
                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <a
                                        className="link-container  "
                                        href="/pmg"
                                    >
                                        <div
                                            className="bg-no-repeat border-color-stone     padding-left-base padding-top-xsmall padding-bottom-xsmall border-bottom-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-stone"
                                            id=""
                                            style={{
                                                width: "100%",
                                                backgroundImage:
                                                    'url("https://m.media-amazon.com/images/G/01/PMG/Squid_ink_sRGB._SL1280_FMpng_.png")',
                                                backgroundSize: "cover",
                                                backgroundPosition: "center center"
                                            }}
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Fuse_Logo_4C_White-Blue-500px_width_copy.png"
                                                    alt=""
                                                    title=""
                                                    height={50}
                                                    style={{ height: 50 }}
                                                />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div
                                    className="width100 padding-left-base padding-right-base padding-top-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id=""
                                >
                                    <div
                                        className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-homepage"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                                    GROW
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="text align-start color-gulfstream font-size-small ember">
                                            RESOURCES
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-partner-portal"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Partner Portal
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-brand-guidelines"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Brand and Legal
                                                    <br />
                                                    Guidelines
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="text align-start color-gulfstream font-size-small ember">
                                            GUIDANCE
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-customer-experience-guidance"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Customer Experience (CX) Guidance
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-campaign-key-visual-and-messaging-"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Campaign Key Visual and Messaging
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-campaign-planning"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Campaign Planning
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-customer-lifecycle-management"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Customer Lifecycle Management
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                        >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-retail-plan"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-normal">
                                                    Retail
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <a
                                            className="link-container  "
                                            href="/pmg/pmg-grow-campaign-approval-process"
                                        >
                                            <div
                                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h3 className="heading align-start font-size-xsmall color-gulfstream ember font-bold">
                                                    Campaign Approval Process
                                                </h3>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div
                        className="width79 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id=""
                    >
                        <PmgNavBar/>
                        <div
                            className="width100 border-color-snow     border-left-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-snow"
                            id=""
                        >
                            <div
                                className="bg-no-repeat     padding-left-xxxlarge padding-right-xxxlarge padding-top-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                                style={{
                                    width: "100%",
                                    backgroundImage:
                                        'url("https://m.media-amazon.com/images/G/01/PMG/Wavy_Background_06.22.2_copy._SL1280_FMpng_.png")',
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center"
                                }}
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width62 padding-right-minibase flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 padding-right-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <h5 className="heading align-start font-size-xlarge color-gulfstream ember font-bold">
                                                {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.growTitle}
                                            </h5>
                                        </div>

                                        <div
                                            className="padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                            style={{ width: "100%" }}
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.growDescriptionP1}
                                                <br />
                                                <br />
                                                {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.growDescriptionP2}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="width37 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="image align-start">
                                            <img
                                                src={contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.landingImage.fields.file.url}
                                                alt=""
                                                title=""
                                                height="auto"
                                                style={{ height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="image align-start">
                                    <img
                                        src="https://m.media-amazon.com/images/G/01/PMG/Wavy_backgroun_06.22_copy.png"
                                        alt=""
                                        title=""
                                        height="auto"
                                        style={{ height: "auto" }}
                                    />
                                </div>
                            </div>
                            <div
                                className="width100 padding-top-minibase padding-bottom-minibase flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="spacer" />
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 padding-right-base flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="has-max-width">
                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.brandAndLegalCheck}
                                            </h5>
                                        </div>
                                    </div>
                                    <div
                                        className="width70 padding-left-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 padding-bottom-large flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.brandAndLegalCheckDescription}
                                            </h5>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div
                                                    className="width100 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                                        Brand Guidelines
                                                    </h5>
                                                </div>
                                                <div
                                                    className="width100 padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <a
                                                        className="link-container  "
                                                        href="https://a2z-partners.enterprise.app.tenovos.io/#/home"
                                                        target="_blank"
                                                    >
                                                        <div
                                                            className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                            style={{
                                                                width: "100%",
                                                                backgroundImage:
                                                                    'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                                backgroundSize: "cover",
                                                                backgroundPosition: "center center"
                                                            }}
                                                        >
                                                            <div className="text align-center color-pearl font-size-small ember">
                                                                Learn more
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="spacer" />
                                            </div>
                                            <div
                                                className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                                        Legal Requirements
                                                    </h5>
                                                </div>
                                                <div
                                                    className="width100 padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <a
                                                        className="link-container  "
                                                        href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=6e77402a-6415-401d-a60d-d4072053507c"
                                                        target="_blank"
                                                    >
                                                        <div
                                                            className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                            style={{
                                                                width: "100%",
                                                                backgroundImage:
                                                                    'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                                backgroundSize: "cover",
                                                                backgroundPosition: "center center"
                                                            }}
                                                        >
                                                            <div className="text align-center color-pearl font-size-small ember">
                                                                Learn more
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xlarge padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 padding-right-base flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="has-max-width">
                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.checklistTitle}
                                            </h5>
                                        </div>
                                    </div>
                                    <div
                                        className="width70 padding-left-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width12 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/check_icon.png"
                                                        alt=""
                                                        title=""
                                                        height={25}
                                                        style={{ height: 25 }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="width87 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.checkList.fields.checklist1}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                                    alt=""
                                                    title=""
                                                    height="auto"
                                                    style={{ height: "auto" }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width12 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/check_icon.png"
                                                        alt=""
                                                        title=""
                                                        height={25}
                                                        style={{ height: 25 }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="width87 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.checkList.fields.checklist2}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                                    alt=""
                                                    title=""
                                                    height="auto"
                                                    style={{ height: "auto" }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width12 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/check_icon.png"
                                                        alt=""
                                                        title=""
                                                        height={25}
                                                        style={{ height: 25 }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="width87 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.checkList.fields.checklist3}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                                    alt=""
                                                    title=""
                                                    height="auto"
                                                    style={{ height: "auto" }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width12 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/check_icon.png"
                                                        alt=""
                                                        title=""
                                                        height={25}
                                                        style={{ height: 25 }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="width87 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.checkList.fields.checklist4}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                                    alt=""
                                                    title=""
                                                    height="auto"
                                                    style={{ height: "auto" }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width12 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/check_icon.png"
                                                        alt=""
                                                        title=""
                                                        height={25}
                                                        style={{ height: 25 }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="width87 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.checkList.fields.checklist5}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                                    alt=""
                                                    title=""
                                                    height="auto"
                                                    style={{ height: "auto" }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width12 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/check_icon.png"
                                                        alt=""
                                                        title=""
                                                        height={25}
                                                        style={{ height: 25 }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="width87 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.checkList.fields.checklist6}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                                    alt=""
                                                    title=""
                                                    height="auto"
                                                    style={{ height: "auto" }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width12 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/check_icon.png"
                                                        alt=""
                                                        title=""
                                                        height={25}
                                                        style={{ height: 25 }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="width87 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.checkList.fields.checklist7}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                                    alt=""
                                                    title=""
                                                    height="auto"
                                                    style={{ height: "auto" }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width12 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/check_icon.png"
                                                        alt=""
                                                        title=""
                                                        height={25}
                                                        style={{ height: 25 }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="width87 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.checkList.fields.checklist8}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                                    alt=""
                                                    title=""
                                                    height="auto"
                                                    style={{ height: "auto" }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width12 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/check_icon.png"
                                                        alt=""
                                                        title=""
                                                        height={25}
                                                        style={{ height: 25 }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="width87 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.checkList.fields.checklist9}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                                    alt=""
                                                    title=""
                                                    height="auto"
                                                    style={{ height: "auto" }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width12 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/check_icon.png"
                                                        alt=""
                                                        title=""
                                                        height={25}
                                                        style={{ height: 25 }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="width87 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.checkList.fields.checklist10}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                                    alt=""
                                                    title=""
                                                    height="auto"
                                                    style={{ height: "auto" }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width12 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src="https://m.media-amazon.com/images/G/01/PMG/check_icon.png"
                                                        alt=""
                                                        title=""
                                                        height={25}
                                                        style={{ height: 25 }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="width87 border-color-summit     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.checkList.fields.checklist11}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xlarge padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 padding-right-base flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="has-max-width">
                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.approvalToolTitle}
                                            </h5>
                                        </div>
                                    </div>
                                    <div
                                        className="width70 padding-left-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                <strong>1 </strong> Access the Approvals Tool from the
                                                partner portal.
                                                <br />
                                                <br />
                                                <strong>2 </strong> Submit your asset for approval in the
                                                tool.
                                                <br />
                                                <br />
                                                <strong>3</strong> Receive feedback from Amazon marketers in
                                                the Approvals Tool inbox.
                                                <br />
                                                <br />
                                                <strong>4</strong> If no changes are required, Amazon
                                                marketer approves the asset and it is ready for use.
                                                <br />
                                                <br />
                                                If changes are required, re-submit a new version to the
                                                Approvals Tool for Amazon to review. <br />
                                                <br />
                                                Please watch the short video below on how to submit an asset
                                                for approval, access feedback from Amazon, and receive
                                                approval on marketing creatives:
                                            </div>
                                        </div>
                                        <div
                                            className="bg-no-repeat border-color-summit padding-top-base padding-bottom-base border-left-1px border-top-1px border-bottom-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                            id=""
                                            style={{
                                                width: "100%",
                                                backgroundImage:
                                                    'url("https://m.media-amazon.com/images/G/01/PMG/Submitting_assets_for_approval_Background._SL1280_FMpng_.png")',
                                                backgroundSize: "cover",
                                                backgroundPosition: "center center"
                                            }}
                                        >
                                            <div className="video align-start">
                                                <video
                                                    controls
                                                    crossOrigin="anonymous"
                                                    height="auto"
                                                    preload="metadata"
                                                >
                                                    <source
                                                        src="https://m.media-amazon.com/images/G/01/PMG/11_-_Submitting_Assets_for_Approval.mp4"
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xlarge padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 padding-right-base flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="has-max-width">
                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.submitAssetForApprovalTitle}
                                            </h5>
                                        </div>
                                    </div>
                                    <div
                                        className="width70 padding-left-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.submitAssetForApprovalDescription}<br />
                                                <br />
                                                <strong>
                                                    {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.approvalTitle}<br />
                                                    <br />
                                                </strong>
                                                {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.approvalDescription}<br />
                                                <br />
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                                    Approvals Tool User Guide
                                                </h5>
                                            </div>
                                            <div
                                                className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="spacer" />
                                            </div>
                                            <div
                                                className="width33 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                                    {contentfulContext && contentfulContext.PMGDeliverAssetsForApproval.approvalTitle}
                                                </h5>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width33 padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <a
                                                    className="link-container  "
                                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=3ba35c84-8114-4845-910d-282371b7c6bc"
                                                    target="_blank"
                                                >
                                                    <div
                                                        className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage:
                                                                'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center center"
                                                        }}
                                                    >
                                                        <div className="text align-center color-pearl font-size-small ember">
                                                            Learn more
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div
                                                className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="spacer" />
                                            </div>
                                            <div
                                                className="width33 padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <a
                                                    className="link-container  "
                                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetIngest"
                                                    target="_blank"
                                                >
                                                    <div
                                                        className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage:
                                                                'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center center"
                                                        }}
                                                    >
                                                        <div className="text align-center color-pearl font-size-small ember">
                                                            Submit
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width100 padding-top-xlarge padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="image align-start">
                                            <img
                                                src="https://m.media-amazon.com/images/G/01/PMG/Prime_blue_line.png"
                                                alt=""
                                                title=""
                                                height="auto"
                                                style={{ height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-xmini flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-grow-retail-plan"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="image align-start">
                                                        <img
                                                            src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Left-SquidInk-Siren.svg"
                                                            alt=""
                                                            title=""
                                                            height="auto"
                                                            style={{ height: "auto" }}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="width45 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-cumulus font-size-small ember">
                                                PREVIOUS
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-grow-retail-plan"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-start color-gulfstream font-size-small ember">
                                                        Retail
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id=""
                >
                    <div className="spacer" />
                </div>
                <PmgFooter/>
            </div>
        </div>
    )
}

export default PmgGrowSubmitForApproval;