import React, { useEffect } from 'react';

import Footer from '../sections/Footer';
import Benefits from '../sections/Benefit';
import Services from '../sections/Service';
import HowItWorks from '../sections/HowItWork';
import LandingPage from '../sections/LandingPage';
import NavigationBar from '../sections/NavigationBar';

const HomePage = ({ onLoad }) => {
    useEffect(() => {
        onLoad();
    }, []);
    
    return (
        <>
            <LandingPage/>
            <NavigationBar/>
            <Services/>
            <Benefits/>
            <HowItWorks/>
            <Footer/>
        </>
    );
};

export default HomePage;