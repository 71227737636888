import React from "react";
import { useContentfulContext } from "src/context/ContentfulContext";

const Services = () => {
    const contentfulContext = useContentfulContext();
    return (
        <div className="desktop content" data-devicetype="desktop">
            <div className="flex-container flex-align-items-stretch
                flex-align-content-flex-start flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                <div className="flex-container
                    flex-align-items-stretch flex-align-content-flex-start
                    flex-full-width fuse ember" id="" style={{ width: "100%" }}></div>
                <div className="padding-left-base padding-right-base
                        padding-top-xxxlarge padding-bottom-xlarge
                        flex-container flex-align-items-stretch
                        flex-align-content-flex-start flex-full-width fuse ember
                        horizontal-rule" id="services" style={{ width: "100%" }}>
                    <div className="has-max-width">
                        <div className="padding-top-large
                                padding-bottom-xxxlarge flex-container
                                flex-align-items-stretch
                                flex-align-content-flex-start flex-full-width
                                fuse ember" id="" style={{ width: "100%" }}>
                            <div className="has-max-width max-width-slim">
                                <h2 className="heading align-center">{contentfulContext && contentfulContext.Service.title}</h2>
                            </div>
                        </div>
                        <div className="flex-container flex-align-items-stretch
                                flex-align-content-flex-start flex-full-width
                                fuse ember" id="" style={{ width: "100%" }}>
                            <div className="padding-right-base padding-top-mini
                                    flex-container flex-align-items-stretch
                                    flex-align-content-flex-start fuse ember" id="" style={{ width: "50%" }}
                                data-aos="fade-up" data-aos-once="true" data-aos-delay="200" data-aos-duration="300">
                                <div data-testid='serviceImage-1' className="image
                                        align-center"><img
                                        src={contentfulContext && contentfulContext.Service.primeService.fields.serviceImage.fields.file.url}
                                        alt="" title="" height="auto" style={{ height: "auto" }} /></div>
                            </div>
                            <div className="flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start fuse ember" id="" style={{ width: "50%" }}>
                                <div className="padding-top-large
                                        padding-bottom-base flex-container
                                        flex-align-items-stretch
                                        flex-align-content-flex-start
                                        flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                    <div className="image
                                            align-start"><img
                                            src={contentfulContext && contentfulContext.Service.primeService.fields.logo.fields.file.url}
                                            alt="" title="" height="auto" style={{ height: "auto" }} /></div>
                                </div>
                                <div className="flex-container
                                        flex-align-items-stretch
                                        flex-align-content-flex-start
                                        flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                    <h4 className="heading
                                            align-start">{contentfulContext && contentfulContext.Service.primeService.fields.name}</h4>
                                </div>
                                <div className="flex-container
                                        flex-align-items-stretch
                                        flex-align-content-flex-start
                                        flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                    <div className="text
                                            align-start color-squidink
                                            font-size-regular">{contentfulContext && contentfulContext.Service.primeService.fields.description}</div>
                                </div>
                                <div className="flex-container
                                        flex-align-items-stretch
                                        flex-align-content-flex-start
                                        flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                    <div className="text
                                            align-start color-squidink
                                            font-size-small"><em>{contentfulContext && contentfulContext.Service.primeService.fields.note}</em></div>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="padding-left-base padding-right-base
                        padding-top-xxlarge padding-bottom-xxxlarge
                        flex-container flex-align-items-stretch
                        flex-align-content-flex-start flex-full-width fuse ember
                        horizontal-rule" id="" style={{ width: "100%" }}>
                    <div className="has-max-width">
                        <div className="flex-container
                                flex-align-items-stretch
                                flex-align-content-flex-start fuse ember" id="" style={{ width: "50%" }}>
                            <div className="padding-bottom-base flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start
                                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                <div data-testid='serviceImage-2' className="image
                                        align-start"><img
                                        src={contentfulContext && contentfulContext.Service.primeVideoService.fields.logo.fields.file.url}
                                        alt="" title="" height="auto" style={{ height: "auto" }} /></div>
                            </div>
                            <div className="padding-top-xlarge flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start
                                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                <h4 className="heading
                                        align-start">{contentfulContext && contentfulContext.Service.primeVideoService.fields.name}</h4>
                            </div>
                            <div className="flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start
                                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                <div className="text
                                        align-start color-squidink
                                        font-size-regular">{contentfulContext && contentfulContext.Service.primeVideoService.fields.description}
                                </div>
                            </div>
                            <div className="flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start
                                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                <div className="text
                                        align-start color-squidink
                                        font-size-small"><em>{contentfulContext && contentfulContext.Service.primeVideoService.fields.note}</em> </div>
                            </div>
                        </div>
                        <div className="padding-left-base flex-container
                                flex-align-items-stretch
                                flex-align-content-flex-start fuse ember" id="" style={{ width: "50%" }} data-aos="fade-up"
                            data-aos-once="true" data-aos-delay="200" data-aos-duration="300">
                            <div className="image
                                    align-center"><img
                                    src={contentfulContext && contentfulContext.Service.primeVideoService.fields.serviceImage.fields.file.url}
                                    alt="" title="" height="auto" style={{ height: "auto" }} /></div>
                        </div>
                    </div>
                </div>
                <div className="padding-left-base padding-right-base
                        padding-top-xxxlarge padding-bottom-xxxlarge
                        flex-container flex-align-items-stretch
                        flex-align-content-flex-start flex-full-width fuse
                        ember horizontal-rule" id="" style={{ width: "100%" }}>
                    <div className="has-max-width">
                        <div className="padding-right-base
                                flex-container flex-align-items-stretch
                                flex-align-content-flex-start fuse ember" id="" style={{ width: "50%" }} data-aos="fade-up"
                            data-aos-once="true" data-aos-delay="200" data-aos-duration="300">
                            <div data-testid='serviceImage-3' className="image
                                    align-center"><img
                                    src={contentfulContext && contentfulContext.Service.musicService.fields.serviceImage.fields.file.url}
                                    alt="" title="" height="auto" style={{ height: "auto" }} /></div>
                        </div>
                        <div className="flex-container flex-align-items-stretch
                                flex-align-content-flex-start fuse ember" id="" style={{ width: "50%" }}>
                            <div className="padding-bottom-minibase
                                    flex-container flex-align-items-stretch
                                    flex-align-content-flex-start
                                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                <div className="image
                                        align-start"><img
                                        src={contentfulContext && contentfulContext.Service.musicService.fields.logo.fields.file.url}
                                        alt="" title="" height="auto" style={{ height: "auto" }} /></div>
                            </div>
                            <div className="flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start
                                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                <h4 className="heading
                                        align-start">{contentfulContext && contentfulContext.Service.musicService.fields.name}</h4>
                            </div>
                            <div className="flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start
                                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                <div className="text
                                        align-start color-squidink
                                        font-size-regular">{contentfulContext && contentfulContext.Service.musicService.fields.description}</div>
                            </div>
                            <div className="flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start
                                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                <div className="flex-container
                                        flex-align-items-stretch
                                        flex-align-content-flex-start
                                        flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                    <div className="text
                                            align-start color-squidink
                                            font-size-small"><em>{contentfulContext && contentfulContext.Service.musicService.fields.note}</em></div>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="padding-left-base padding-right-base
                        padding-top-xxlarge padding-bottom-xxxlarge
                        flex-container flex-align-items-stretch
                        flex-align-content-flex-start flex-full-width fuse ember
                        horizontal-rule" id="" style={{ width: "100%" }}>
                    <div className="has-max-width">
                        <div className="flex-container
                                flex-align-items-stretch
                                flex-align-content-flex-start fuse ember" id="" style={{ width: "50%" }}>
                            <div className="padding-bottom-base flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start
                                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                <div data-testid='serviceImage-2' className="image
                                        align-start"><img
                                    src={contentfulContext && contentfulContext.Service.audibleService.fields.logo.fields.file.url}
                                    alt="" title="" height="auto" style={{ height: "auto" }} /></div>
                            </div>
                            <div className="padding-top-xlarge flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start
                                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                <h4 className="heading
                                        align-start">{contentfulContext && contentfulContext.Service.audibleService.fields.name}</h4>
                            </div>
                            <div className="flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start
                                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                <div className="text
                                        align-start color-squidink
                                        font-size-regular">{contentfulContext && contentfulContext.Service.audibleService.fields.description}
                                </div>
                            </div>
                            <div className="flex-container
                                    flex-align-items-stretch
                                    flex-align-content-flex-start
                                    flex-full-width fuse ember" id="" style={{ width: "100%" }}>
                                <div className="text
                                        align-start color-squidink
                                        font-size-small"><em>{contentfulContext && contentfulContext.Service.audibleService.fields.note}</em> </div>
                            </div>
                        </div>
                        <div className="padding-left-base flex-container
                                flex-align-items-stretch
                                flex-align-content-flex-start fuse ember" id="" style={{ width: "50%" }} data-aos="fade-up"
                             data-aos-once="true" data-aos-delay="200" data-aos-duration="300">
                            <div className="image
                                    align-center"><img
                                src={contentfulContext && contentfulContext.Service.audibleService.fields.serviceImage.fields.file.url}
                                alt="" title="" height="auto" style={{ height: "auto" }} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
