import React from 'react';
import { useContentfulContext } from 'src/context/ContentfulContext';

const PmgFooter = () => {

    const contentfulContext = useContentfulContext();

    return (
        <><div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
            id="" >
            <div className="spacer"></div>
        </div><div className="bg-no-repeat     padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
            id=""
            style={{
                width: "100%",
                backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Squid_ink_sample_sRGB-01._SL1280_FMpng_.png')`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}
        ><div className="has-max-width">
                    <div className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                        id="" >
                        <div className="spacer"></div>
                    </div>
                    <div className="width100 border-color-stone     padding-top-minibase padding-bottom-mini border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-stone"
                        id="" >
                        <h5 className="heading align-start font-size-small color-cloud ember font-normal"><strong>{
                            contentfulContext && contentfulContext.PMGFooter.usefulLinks}</strong></h5>
                    </div>
                    <div className="width100 padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                        id="" >
                        <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "16.666666666666668%" }}>
                            <div className="width100 padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" ><a className="link-container  " style={{ textDecoration: "none" }}
                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/home" target="_blank">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <div className="text align-start color-summit font-size-xsmall ember">{
                                            contentfulContext && contentfulContext.PMGFooter.partnerPortal}
                                        </div>
                                    </div>
                                </a></div>
                            <div className="width100 padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" ><a className="link-container  " style={{ textDecoration: "none" }}
                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=6e77402a-6415-401d-a60d-d4072053507c"
                                    target="_blank">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <div className="text align-start color-summit font-size-xsmall ember">{
                                            contentfulContext && contentfulContext.PMGFooter.legalRequirements}
                                        </div>
                                    </div>
                                </a></div>
                        </div>
                        <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "16.666666666666668%" }}>
                            <div className="width100 padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" ><a className="link-container  " style={{ textDecoration: "none" }}
                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=596d4b86-fc5b-4e98-9ad0-6876f86b5e61"
                                    target="_blank">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <div className="text align-start color-summit font-size-xsmall ember">{
                                            contentfulContext && contentfulContext.PMGFooter.primeGuidelines}
                                        </div>
                                    </div>
                                </a></div>
                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" ><a className="link-container  " style={{ textDecoration: "none" }}
                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=f6b861d8-c4e5-48f3-924b-1e6137a759f7"
                                    target="_blank">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <div className="text align-start color-summit font-size-xsmall ember">{
                                            contentfulContext && contentfulContext.PMGFooter.primeVideoGuidelines}</div>
                                    </div>
                                </a></div>
                        </div>
                        <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "20.833333333333332%" }}>
                            <div className="width100 padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" ><a className="link-container  " style={{ textDecoration: "none" }}
                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=d4f7f592-91f8-469e-8bf3-6b836c1241f6"
                                    target="_blank">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <div className="text align-start color-summit font-size-xsmall ember">{
                                            contentfulContext && contentfulContext.PMGFooter.pvmeGuidelines}
                                        </div>
                                    </div>
                                </a></div>
                            <div className="width100 padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" ><a className="link-container  " style={{ textDecoration: "none" }}
                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=25f79db2-4ee9-4a06-b1fd-3442aa785672"
                                    target="_blank">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <div className="text align-start color-summit font-size-xsmall ember">{
                                            contentfulContext && contentfulContext.PMGFooter.primeGamingGuidelines}</div>
                                    </div>
                                </a></div>
                        </div>
                        <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "20.833333333333332%" }}>
                            <div className="width100 padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" ><a className="link-container  " style={{ textDecoration: "none" }}
                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=4b00a86c-3677-4bf9-b4e8-83eff235ba9f"
                                    target="_blank">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <div className="text align-start color-summit font-size-xsmall ember">{
                                            contentfulContext && contentfulContext.PMGFooter.amazonMusicGuidelines}</div>
                                    </div>
                                </a></div>
                            <div className="width100 padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" ><a className="link-container  " style={{ textDecoration: "none" }}
                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=ad3282b4-48dc-4ad4-8604-a5f9fe4fafaf"
                                    target="_blank">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <div className="text align-start color-summit font-size-xsmall ember">{
                                            contentfulContext && contentfulContext.PMGFooter.audibleGuidelines}
                                        </div>
                                    </div>
                                </a></div>
                        </div>
                        <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "25%" }}>
                            <div className="width100 padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-justify-content flex-justify-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" ><a className="link-container  " style={{ textDecoration: "none" }}
                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=1e0ebc8a-4f5f-4f8e-81aa-b2254039a2e0"
                                    target="_blank">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <div className="text align-start color-summit font-size-xsmall ember">{
                                            contentfulContext && contentfulContext.PMGFooter.kindleUnlimitedGuidelines}</div>
                                    </div>
                                </a></div>
                            <div className="width100 padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-justify-content flex-justify-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" ><a className="link-container  " style={{ textDecoration: "none" }}
                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=c19c9714-d3df-4e00-826a-f829d84343ea"
                                    target="_blank">
                                    <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id="" >
                                        <div className="text align-start color-summit font-size-xsmall ember">{
                                            contentfulContext && contentfulContext.PMGFooter.customerExperienceCxPlaybook}</div>
                                    </div>
                                </a></div>
                        </div>
                    </div>
                    <div className="width100 border-color-stone     border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-stone"
                        id="" >
                        <div className="spacer"></div>
                    </div>
                    <div className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                        id="" >
                        <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "20.833333333333332%" }}><a className="link-container  " style={{ textDecoration: "none" }}
                                href="/pmg">
                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="image align-start"><img
                                        src={contentfulContext && contentfulContext.PMGFooter.pmgFooterFuseLogo.fields.file.url}
                                        alt="" title="" height="25" style={{ height: "25px" }}></img></div>
                                </div>
                            </a></div>
                        <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "20.833333333333332%" }}><a className="link-container  " style={{ textDecoration: "none" }}
                                href="mailto:pmgfeedback@amazon.com">
                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="text align-start color-cloud font-size-xsmall ember">{
                                        contentfulContext && contentfulContext.PMGFooter.pmgFeedback}</div>
                                </div>
                            </a></div>
                        <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "16.666666666666668%" }}><a className="link-container  " style={{ textDecoration: "none" }}
                                href="https://www.amazon.com/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ">
                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="text align-start color-cloud font-size-xsmall ember">{
                                        contentfulContext && contentfulContext.PMGFooter.privacyNotice}</div>
                                </div>
                            </a></div>
                        <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "41.666666666666664%" }}>
                            <div className="text align-end color-cloud font-size-xsmall ember">{
                                contentfulContext && contentfulContext.PMGFooter.copyRights}</div>
                        </div>
                    </div>
                </div>
            </div><div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                id="" >
                <div className="spacer"></div>
            </div></>
    )
}

export default PmgFooter;