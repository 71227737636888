import React from 'react'
import { useContentfulContext } from 'src/context/ContentfulContext';
import PmgFooter from '../components/PmgFooter';

const PmgPage = () => {

    const contentfulContext = useContentfulContext();

    return (
        <div className="desktop" data-devicetype="desktop">
            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                id="" >
                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id="" >
                    <div className="spacer"></div>
                </div>
                <div className="bg-repeat border-color-storm     padding-left-xxxlarge padding-right-xxxlarge padding-top-xsmall padding-bottom-xsmall border-bottom-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-storm is-sticky sticks-top"
                    id=""
                    style={{
                        width: "100%",
                        backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Squid_Ink-_sample_sRGB-01._SL1280_FMpng_.png')`,
                        backgroundSize: "initial",
                        backgroundPosition: "center"
                    }}
                ><div className="has-max-width">
                        <div className="padding-top-xmini flex-container flex-align-items-center flex-align-content-center flex-justify-content flex-justify-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "20.833333333333332%" }}><a className="link-container"
                                href="/pmg">
                                <div className="width100 flex-container flex-align-items-center flex-align-content-center flex-justify-content flex-justify-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="image align-start">
                                        <img
                                            src={contentfulContext && contentfulContext.PMGNavBar2.fuseLogo.fields.file.url}
                                            alt="" title="" height="50" style={{ height: "50px" }}></img></div>
                                </div>
                            </a></div>
                        <div className="border-color-storm     padding-left-xlarge border-left-1px flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-storm"
                            id="" style={{ width: "41.666666666666664%" }}>
                            <h5 className="heading align-start font-size-regular color-horizon ember font-normal"><strong>{
                                contentfulContext && contentfulContext.PMGNavBar2.title}</strong></h5>
                        </div>
                        <div className="padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "25%" }}>
                            <div className="spacer"></div>
                        </div>
                        <div className="flex-container flex-align-items-center flex-align-content-center flex-justify-content flex-justify-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "8.333333333333334%" }}><a className="link-container" style={{ textDecoration: "none" }}
                                href="/pmg/pmg-start-homepage">
                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="text align-start color-horizon font-size-small ember">{
                                contentfulContext && contentfulContext.PMGNavBar2.start}</div>
                                </div>
                            </a></div>
                        <div className="flex-container flex-align-items-center flex-align-content-center flex-justify-content flex-justify-content-flex-end amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "4.166666666666667%" }}><a className="link-container" style={{ textDecoration: "none" }}
                                href="/pmg/pmg-grow-homepage">
                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-justify-content flex-justify-content-flex-end flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="text align-start color-horizon font-size-small ember">{
                                contentfulContext && contentfulContext.PMGNavBar2.grow}</div>
                                </div>
                            </a></div>
                    </div>
                </div>
                <div className="bg-no-repeat     padding-left-xxxlarge padding-right-xxxlarge padding-top-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id=""
                    style={{
                        width: "100%",
                        backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Wavy_bg_Squid_Ink_2._SL1280_FMpng_.png')`,
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                    }}
                ><div className="has-max-width">
                        <div className="flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "50%" }}>
                            <div className="image align-center"><img
                                src="https://m.media-amazon.com/images/G/01/sp-marketing-toolkit/guides/design/illustration/Vignettes/Ship-Hangar.png"
                                alt="" title="" height="350" style={{ height: "350px" }}></img></div>
                        </div>
                        <div className="padding-left-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "50%" }}>
                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="" style={{ width: "29.166666666666668%" }}>
                                    <div className="text align-start color-cloud font-size-small ember">HOME</div>
                                </div>
                                <div></div>
                            </div>
                            <div className="width100 padding-right-xlarge padding-top-xsmall padding-bottom-xsmall flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <h5 className="heading align-start h5 color-cloud ember font-bold">Welcome to the Partner Marketing
                                    Guide.</h5>
                            </div>
                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="" style={{ width: "83.33333333333333%" }}>
                                    <div className="text align-start color-cloud font-size-small ember">{
                                contentfulContext && contentfulContext.PMGLandingPage.description}</div>
                                </div>
                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                    id="" style={{ width: "16.666666666666668%" }}>
                                    <div className="spacer"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id="" >
                    <div className="image align-start"><img src="https://m.media-amazon.com/images/G/01/PMG/Wavy_bg_Squid_Ink.jpg"
                        alt="" title="" height="auto" style={{ height: "auto" }}></img></div>
                </div>
                <div className="width100 padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id="" >
                    <div className="spacer"></div>
                </div>
                <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id="" >
                    <div className="has-max-width">
                        <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "50%" }}><a className="link-container" style={{ textDecoration: "none" }}
                                href="/pmg/pmg-start-homepage ">
                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="image align-center"><img
                                        src={contentfulContext && contentfulContext.PMGLandingPage.newPartner.fields.file.url} alt=""
                                        title="" height="auto" style={{ height: "auto" }}></img></div>
                                </div>
                            </a></div>
                        <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "50%" }}><a className="link-container" style={{ textDecoration: "none" }}
                                href="/pmg/pmg-grow-homepage">
                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="image align-center"><img
                                        src={contentfulContext && contentfulContext.PMGLandingPage.existingPartner.fields.file.url} alt=""
                                        title="" height="auto" style={{ height: "auto" }}></img></div>
                                </div>
                            </a></div>
                    </div>
                </div>
                <div className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id="" >
                    <div className="spacer"></div>
                </div>
                <div className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id="" >
                    <div className="has-max-width">
                        <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "50%" }}>
                            <div className="image align-center"><img
                                src={contentfulContext && contentfulContext.PMGLandingPage.feedbackImage.fields.file.url} alt="" title=""
                                height="400" style={{ height: "400px" }}></img></div>
                        </div>
                        <div className="padding-left-large flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                            id="" style={{ width: "50%" }}>
                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <h3 className="heading align-start font-size-medium color-gulfstream ember font-normal">{contentfulContext && contentfulContext.PMGLandingPage.feedbackTitle}</h3>
                            </div>
                            <div className="width100 padding-top-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="spacer"></div>
                            </div>
                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="text align-start color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGLandingPage.feedbackDescription}</div>
                            </div>
                            <div className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="spacer"></div>
                            </div>
                            <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id="" >
                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" ><a className="link-container" style={{ textDecoration: "none" }} href="mailto:pmgfeedback@amazon.com"
                                        target="_blank">
                                        <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id="" >
                                            <div className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                                style={{
                                                    width: "41.666666666666664%",
                                                    backgroundImage: `url('https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png')`,
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center"
                                                }}
                                            ><div className="text align-center color-gulfstream font-size-small ember">{contentfulContext && contentfulContext.PMGLandingPage.feedbackButton}
                                                </div>
                                            </div>
                                            <div className="flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id="" style={{ width: "58.333333333333336%" }}>
                                                <div className="spacer"></div>
                                            </div>
                                        </div>
                                    </a></div>
                                <div className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                    id="" >
                                    <div className="spacer"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-large padding-bottom-large flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id="" >
                    <div className="has-max-width">
                        <div className="spacer"></div>
                    </div>
                </div>
                <PmgFooter/>
            </div>
        </div>
    )
}


export default PmgPage;