import * as contentful from 'contentful';
import constants from 'src/constants';


const spaceId = 'uzidk21bcjj8';
export const client = contentful.createClient(getContentfulSecrets());

function getContentfulSecrets() {
    let accessToken;
    let contentfulHost;

    if (isPreviewSite()) {
        contentfulHost = 'preview.contentful.com';
        accessToken = 'WOPK3KbGyVdlmdN3F5ogw2QqQitYHctTGVHpK6Ltwcw';
    } else {
        contentfulHost = 'cdn.contentful.com';
        accessToken = 'tpcIthSKTkRC5TlL8EiHSS3UFqukB1VVK_ErHFjMnAk';
    }

    return {
        space: spaceId,
        accessToken: accessToken,
        host: contentfulHost
    }
}

function isPreviewSite() {
    const currentRequestHostName = window.location.hostname;
    return currentRequestHostName === constants.PREVIEW_HOST_NAMES.BETA ||
        currentRequestHostName === constants.PREVIEW_HOST_NAMES.PROD
}