import React from 'react';
import { KatSpinner } from '@amzn/katal-react';
import './Loading.styles.scss';
 
const Loading = () => {
    return (
        <div className='loading'>
            <KatSpinner size='large' />
        </div>
    )
}
 
export default Loading;