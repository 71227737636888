import React from "react";
import PmgFooter from "src/pmg/components/PmgFooter";
import PmgSideBarTimeLine from "src/pmg/components/PmgSideBarTimeLine";
import PmgNavBar from "src/pmg/components/PmgNavBar";
import {useContentfulContext} from "src/context/ContentfulContext";
import constants from "src/constants";

const PmgDevelopKeyVisualAndMessaging = () => {
    const contentfulContext = useContentfulContext();
    return (
        <div className="desktop" data-devicetype="desktop">
            <div
                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                id=""
            >
                <div
                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id=""
                >
                    <div className="spacer" />
                </div>
                <div
                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                    id=""
                >
                    <div
                        className="width20 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id=""
                    >
                        <div
                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                            id=""
                        >
                            <div className="spacer" />
                        </div>
                        <div
                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent is-sticky sticks-top"
                            id=""
                        >
                            <PmgSideBarTimeLine currentPageName={constants.PMG_START_SIDE_TIMELINE_NAMES.DEVELOP_KVM}/>
                        </div>
                    </div>
                    <div

                        className="width79 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                        id=""
                    >
                        <PmgNavBar/>
                        <div
                            className="width100 border-color-snow     border-left-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-snow"
                            id=""
                        >
                            <div
                                className="bg-no-repeat     padding-left-xxxlarge padding-right-xxxlarge padding-top-base flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                                style={{
                                    width: "100%",
                                    backgroundImage:
                                        'url("https://m.media-amazon.com/images/G/01/PMG/Wavy_Background_06.22.2_copy._SL1280_FMpng_.png")',
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center"
                                }}
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width50 padding-right-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 padding-right-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <h5 className="heading align-start font-size-xlarge color-gulfstream ember font-bold">
                                                {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.title1}
                                            </h5>
                                        </div>
                                        <div
                                            className="width100 padding-top-mini padding-bottom-mini flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="bg-repeat-y     padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                                style={{
                                                    width: "4.166666666666667%",
                                                    backgroundImage:
                                                        'url("https://m.media-amazon.com/images/G/01/PMG/Border-Prime_blue._SL1280_FMpng_.png")',
                                                    backgroundSize: "initial",
                                                    backgroundPosition: "top left"
                                                }}
                                            >
                                                <div className="spacer" />
                                            </div>
                                            <div
                                                className="width95 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h5 className="heading align-start font-size-regular color-gulfstream ember font-bold">
                                                    {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.title2}
                                                </h5>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-right-large padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.description1}
                                                <br />
                                                <br />
                                                {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.description2}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="spacer" />
                                    </div>
                                    <div
                                        className="width41 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="image align-start">
                                            <img
                                                src={contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.image.fields.file.url}
                                                alt=""
                                                title=""
                                                height="auto"
                                                style={{ height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="image align-start">
                                    <img
                                        src="https://m.media-amazon.com/images/G/01/PMG/Wavy_backgroun_06.22_copy.png"
                                        alt=""
                                        title=""
                                        height="auto"
                                        style={{ height: "auto" }}
                                    />
                                </div>
                            </div>
                            <div
                                className="width100 padding-top-minibase padding-bottom-minibase flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="spacer" />
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xlarge flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 padding-right-base padding-top-mini padding-bottom-small flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                            {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.componentHeading}
                                        </h5>
                                    </div>
                                    <div
                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width33 flex-container flex-align-items-flex-start flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width100 padding-bottom-small flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div
                                                    className="width25 padding-top-small padding-bottom-mini flex-container flex-align-items-flex-start flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                        <strong>1.</strong>
                                                    </h5>
                                                </div>
                                                <div
                                                    className="width75 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-start color-gulfstream font-size-small ember">
                                                        {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.component1}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="width100 border-color-summit     padding-top-small padding-bottom-small border-top-1px flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div
                                                    className="width25 padding-top-small padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                        <strong>2.</strong>
                                                    </h5>
                                                </div>
                                                <div
                                                    className="width75 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-start color-gulfstream font-size-small ember">
                                                        {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.component2}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="width100 border-color-summit     padding-top-mini padding-bottom-mini border-top-1px flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div
                                                    className="width25 padding-top-small padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                        <strong>3.</strong>
                                                    </h5>
                                                </div>
                                                <div
                                                    className="width75 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-start color-gulfstream font-size-small ember">
                                                        {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.component3}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="width100 border-color-summit     padding-top-small padding-bottom-small border-top-1px flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div
                                                    className="width25 padding-top-small padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                        <strong>4.</strong>
                                                    </h5>
                                                </div>
                                                <div
                                                    className="width75 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-start color-gulfstream font-size-small ember">
                                                        {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.component4}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="width100 border-color-summit     padding-top-mini border-top-1px flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div
                                                    className="width25 padding-top-small padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                        <strong>5.</strong>
                                                    </h5>
                                                </div>
                                                <div
                                                    className="width75 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-start color-gulfstream font-size-small ember">
                                                        {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.component5}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="width66 padding-top-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src={contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.componentImage.fields.file.url}
                                                    alt=""
                                                    title=""
                                                    height="auto"
                                                    style={{ height: "auto" }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xlarge padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.coBrandingHeading}
                                            </h5>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="spacer" />
                                        </div>
                                    </div>
                                    <div
                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.coBrandingDescription1}
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-large flex-container flex-align-items-center flex-align-content-center flex-justify-content flex-justify-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src={contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.coBrandingImage1.fields.file.url}
                                                    alt=""
                                                    title=""
                                                    height="auto"
                                                    style={{ height: "auto" }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.coBrandingDescription2}
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-center flex-align-content-center flex-justify-content flex-justify-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src={contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.coBrandingImage2.fields.file.url}
                                                    alt=""
                                                    title=""
                                                    height="auto"
                                                    style={{ height: "auto" }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="spacer" />
                                        </div>
                                        <div
                                            className="width100 padding-top-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width58 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.coBrandingDescription3}
                                                </div>
                                            </div>
                                            <div
                                                className="width8 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="spacer" />
                                            </div>
                                            <div
                                                className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div
                                                    className="width100 padding-bottom-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-start color-gulfstream font-size-small ember">
                                                        {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.coBrandingDescription4}
                                                    </div>
                                                </div>
                                                <div
                                                    className="width100 flex-container flex-align-items-flex-start flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <a
                                                        className="link-container  "
                                                        href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=b6c313cd-216e-482e-8aca-2d66d6ee49d8"
                                                        target="_blank"
                                                    >
                                                        <div
                                                            className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                            style={{
                                                                width: "100%",
                                                                backgroundImage:
                                                                    'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                                backgroundSize: "cover",
                                                                backgroundPosition: "center center"
                                                            }}
                                                        >
                                                            <div className="text align-center color-pearl font-size-small ember">
                                                                Learn more
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xlarge padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                            {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.offerMessagingHeading}
                                        </h5>
                                    </div>
                                    <div
                                        className="width70 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="bg-no-repeat     padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                            style={{
                                                width: "100%",
                                                backgroundImage:
                                                    'url("https://s3.amazonaws.com/prod-widgetSource/in-shop/pub/images/amzn_favicon_blk.png")',
                                                backgroundSize: "cover",
                                                backgroundPosition: "center center"
                                            }}
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.offerMessagingDescription1}
                                            </div>
                                        </div>
                                        <div
                                            className="width100 border-color-summit     padding-top-small padding-bottom-small border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-summit"
                                            id=""
                                        >
                                            <div className="text align-start color-gulfstream font-size-small ember">
                                                {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.offerMessagingDescription2}
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width66 border-color-summit     padding-right-xlarge padding-top-small padding-bottom-small border-top-1px flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-summit"
                                                id=""
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.offerMessagingDescription3}
                                                </div>
                                            </div>
                                            <div
                                                className="width33 padding-top-small flex-container flex-align-items-flex-start flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <a
                                                    className="link-container  "
                                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=6e77402a-6415-401d-a60d-d4072053507c"
                                                    target="_blank"
                                                >
                                                    <div
                                                        className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage:
                                                                'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center center"
                                                        }}
                                                    >
                                                        <div className="text align-center color-pearl font-size-small ember">
                                                            Learn more
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src={contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.offerMessageImage1.fields.file.url}
                                                        alt=""
                                                        title=""
                                                        height="auto"
                                                        style={{ height: "auto" }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src={contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.offerMessageImage2.fields.file.url}
                                                        alt=""
                                                        title=""
                                                        height="auto"
                                                        style={{ height: "auto" }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="width33 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src={contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.offerMessageImage3.fields.file.url}
                                                        alt=""
                                                        title=""
                                                        height="auto"
                                                        style={{ height: "auto" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xlarge padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                            {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.imageryHeading}
                                        </h5>
                                    </div>
                                    <div
                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width41 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="bg-no-repeat     padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                                style={{
                                                    width: "70.83333333333333%",
                                                    backgroundImage:
                                                        'url("https://s3.amazonaws.com/prod-widgetSource/in-shop/pub/images/amzn_favicon_blk.png")',
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center center"
                                                }}
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.imageryDescription1}<br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-no-repeat     flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                                style={{
                                                    width: "70.83333333333333%",
                                                    backgroundImage:
                                                        'url("https://s3.amazonaws.com/prod-widgetSource/in-shop/pub/images/amzn_favicon_blk.png")',
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center center"
                                                }}
                                            >
                                                <div className="text align-start color-gulfstream font-size-small ember">
                                                    {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.imageryDescription2}<br />
                                                    <br />
                                                    {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.imageryDescription3}
                                                </div>
                                            </div>
                                            <div
                                                className="width100 padding-top-base flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <a
                                                    className="link-container  "
                                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/home"
                                                    target="_blank"
                                                >
                                                    <div
                                                        className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage:
                                                                'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center center"
                                                        }}
                                                    >
                                                        <div className="text align-center color-pearl font-size-small ember">
                                                            Visit
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div
                                            className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="spacer" />
                                        </div>
                                        <div
                                            className="width54 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src={contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.imageryImage.fields.file.url}
                                                    alt=""
                                                    title=""
                                                    height="auto"
                                                    style={{ height: "auto" }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xlarge padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                            {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.legalDisclaimerHeading}
                                        </h5>
                                    </div>
                                    <div
                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width41 padding-right-base flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div
                                                    className="bg-no-repeat     padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                    style={{
                                                        width: "95.83333333333333%",
                                                        backgroundImage:
                                                            'url("https://s3.amazonaws.com/prod-widgetSource/in-shop/pub/images/amzn_favicon_blk.png")',
                                                        backgroundSize: "cover",
                                                        backgroundPosition: "center center"
                                                    }}
                                                >
                                                    <div className="text align-start color-gulfstream font-size-small ember">
                                                        {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.legalDisclaimerDescription1}<br />
                                                        <br />
                                                        {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.legalDisclaimerDescription2}
                                                    </div>
                                                </div>
                                                <div
                                                    className="width100 padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <a
                                                        className="link-container  "
                                                        href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=6e77402a-6415-401d-a60d-d4072053507c"
                                                        target="_blank"
                                                    >
                                                        <div
                                                            className="bg-no-repeat border-radius-pill    padding-left-small padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                            id=""
                                                            style={{
                                                                width: "100%",
                                                                backgroundImage:
                                                                    'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                                backgroundSize: "cover",
                                                                backgroundPosition: "center center"
                                                            }}
                                                        >
                                                            <div className="text align-center color-pearl font-size-small ember">
                                                                Learn more
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="spacer" />
                                            </div>
                                            <div
                                                className="width54 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <div className="image align-start">
                                                    <img
                                                        src={contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.legalDisclaimerImage.fields.file.url}
                                                        alt=""
                                                        title=""
                                                        height="auto"
                                                        style={{ height: "auto" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 padding-top-xlarge padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="image align-start">
                                                <img
                                                    src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                                    alt=""
                                                    title=""
                                                    height="auto"
                                                    style={{ height: "auto" }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                                {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.legalConsiderationsHeading}
                                            </h5>
                                        </div>
                                        <div
                                            className="width100 padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="spacer" />
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                                <strong>1. </strong> {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.legalConsiderationsPoint1}
                                                <br />
                                                <br />
                                                <strong>2. </strong> {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.legalConsiderationsPoint2}
                                                <br />
                                                <br />
                                                <strong>3.</strong> {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.legalConsiderationsPoint3}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xlarge padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="spacer" />
                                    </div>
                                    <div
                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="text align-start color-gulfstream font-size-small ember">
                                            {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.legalConsiderationsDescription}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge padding-top-xlarge padding-bottom-xlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div className="image align-start">
                                        <img
                                            src="https://m.media-amazon.com/images/G/01/PMG/Grey__line_copy.png"
                                            alt=""
                                            title=""
                                            height="auto"
                                            style={{ height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width29 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <h5 className="heading align-start font-size-regular color-gulfstream ember font-normal">
                                            {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.usefulLinks}
                                        </h5>
                                    </div>
                                    <div
                                        className="width70 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width33 padding-right-small padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                                    {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.legalRequirements}
                                                </h5>
                                            </div>
                                            <div
                                                className="width33 padding-left-mini padding-right-mini padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                                    {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.coBrandingGuidelines}
                                                </h5>
                                            </div>
                                            <div
                                                className="width33 padding-left-small padding-right-base padding-top-mini padding-bottom-mini flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <h5 className="heading align-start font-size-small color-gulfstream ember font-normal">
                                                    {contentfulContext && contentfulContext.PMGDevelopYourKeyVisualAndMessaging.submitKeyVisualAndMessagingForReview}
                                                </h5>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div
                                                className="width33 padding-right-small padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <a
                                                    className="link-container  "
                                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=6e77402a-6415-401d-a60d-d4072053507c"
                                                    target="_blank"
                                                >
                                                    <div
                                                        className="bg-no-repeat border-radius-pill    padding-left-mini padding-right-mini padding-top-xmini padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage:
                                                                'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center center"
                                                        }}
                                                    >
                                                        <div className="text align-center color-pearl font-size-small ember">
                                                            Learn more
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div
                                                className="width33 padding-left-mini padding-right-mini padding-top-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <a
                                                    className="link-container  "
                                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetDetail?id=b6c313cd-216e-482e-8aca-2d66d6ee49d8"
                                                    target="_blank"
                                                >
                                                    <div
                                                        className="bg-no-repeat border-radius-pill    padding-left-mini padding-right-mini padding-top-xmini padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage:
                                                                'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center center"
                                                        }}
                                                    >
                                                        <div className="text align-center color-pearl font-size-small ember">
                                                            Learn more
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div
                                                className="width33 padding-left-small padding-top-small padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                                id=""
                                            >
                                                <a
                                                    className="link-container  "
                                                    href="https://a2z-partners.enterprise.app.tenovos.io/#/assetIngest"
                                                    target="_blank"
                                                >
                                                    <div
                                                        className="bg-no-repeat border-radius-pill    padding-left-mini padding-right-mini padding-top-xmini padding-bottom-xmini flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                        id=""
                                                        style={{
                                                            width: "100%",
                                                            backgroundImage:
                                                                'url("https://m.media-amazon.com/images/G/01/PMG/Prime_Blue_sample_sRGB-01._SL1280_FMpng_.png")',
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center center"
                                                        }}
                                                    >
                                                        <div className="text align-center color-pearl font-size-small ember">
                                                            Submit
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div
                                            className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="spacer" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="width100 padding-left-xxxlarge padding-right-xxxlarge flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                id=""
                            >
                                <div className="has-max-width">
                                    <div
                                        className="width100 padding-top-xlarge padding-bottom-small flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div className="image align-start">
                                            <img
                                                src="https://m.media-amazon.com/images/G/01/PMG/Prime_blue_line.png"
                                                alt=""
                                                title=""
                                                height="auto"
                                                style={{ height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-mini flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-start-outline-a-clear-and-simple-cx"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="image align-start">
                                                        <img
                                                            src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Left-SquidInk-Siren.svg"
                                                            alt=""
                                                            title=""
                                                            height={25}
                                                            style={{ height: 25 }}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="width45 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-start color-cumulus font-size-small ember">
                                                PREVIOUS
                                            </div>
                                        </div>
                                        <div
                                            className="width45 flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <div className="text align-end color-cumulus font-size-small ember">
                                                NEXT
                                            </div>
                                        </div>
                                        <div
                                            className="width4 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-start-create-gtm-plan"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-center flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="image align-end">
                                                        <img
                                                            src="https://m.media-amazon.com/images/G/01/PMG/Icon_UI-Arrow_Right-Siren.svg"
                                                            alt=""
                                                            title=""
                                                            height={25}
                                                            style={{ height: 25 }}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                        className="width100 padding-bottom-large flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                        id=""
                                    >
                                        <div
                                            className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-start-outline-a-clear-and-simple-cx"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-start color-gulfstream font-size-small ember">
                                                        Design a Clear and Simple Customer Experience (CX)
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="width50 flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-transparent"
                                            id=""
                                        >
                                            <a
                                                className="link-container  "
                                                href="/pmg/pmg-start-create-gtm-plan"
                                            >
                                                <div
                                                    className="width100 flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-transparent"
                                                    id=""
                                                >
                                                    <div className="text align-end color-gulfstream font-size-small ember">
                                                        Create Go-To-Market (GTM) Plan
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PmgFooter/>
            </div>
        </div>
    )
}

export default PmgDevelopKeyVisualAndMessaging;